import {
    Dictionary,
    Grid,
    Page,
    PageHero,
} from '@ingeniorforeningen/design-system';
import * as React from 'react';

export interface ILink {
    Url: string;
    Title: string;
}

export interface IDictionary {
    Letter: string;
    Links: ILink[];
}

export interface IDictionaryPageProps {
    Header: string;
    Teaser: string;
    Dictionary: IDictionary[];
}

class DictionaryPage extends React.Component<IDictionaryPageProps> {
    public render() {
        return (
            <Page>
                <PageHero
                    header={this.props.Header}
                    teaser={this.props.Teaser}
                />

                <Grid container justify="center">
                    <Dictionary Dictionary={this.props.Dictionary} />
                </Grid>
            </Page>
        );
    }
}

export default DictionaryPage;
