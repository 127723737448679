import {
    defaultComponentMap,
    Grid,
    SearchField,
} from '@ingeniorforeningen/design-system';
import * as React from 'react';

import dataLayer from '../Analytics/DataLayer';
import ActiveSearch from '../Templates/ActiveSearch';
import JobList from './JobList';
import MediaCta from '../Components/MediaCta';
import LeadFamlyGameEmbed from '../Components/LeadFamlyGameEmbed';
import Form from '../Components/Form';
import MaternityCalculator from '../Components/MaternityCalculator';

const LocalComponentMap = [
    ...defaultComponentMap,
    {
        name: 'gridActiveSearch',
        markup: (props) => {
            const { index, pageProps, control } = props;

            return (
                <ActiveSearch
                    key={index}
                    activeSearchUrl={pageProps.ActiveSearchUrl}
                    {...control.value}
                />
            );
        },
    },
    {
        name: 'gridJobFeed',
        markup: (props) => {
            const { index, control } = props;

            return (
                <JobList
                    heading={control.value.header}
                    readMoreButton={{
                        label: control.value.button.label,
                        href: control.value.button.href,
                        newTab: true,
                    }}
                    items={control.value.jobs}
                    handleOnItemClick={handleItemClick}
                    handleOnReadMoreLinkClick={handleReadMoreClick}
                />
            );
        },
    },
    {
        name: 'umbraco_form_picker',
        markup: (props) => {
            const { index, control } = props;

            return (
                <>
                    <Form data={control.value} />
                </>
            );
        },
    },
    {
        name: 'mediaCta',
        markup: (props) => {
            const { control } = props;

            return (
                <MediaCta
                    mediaUrl={control.value.mediaUrl}
                    mediaType={control.value.mediaType}
                    header={control.value.header}
                    description={control.value.description}
                    buttonLink={control.value.buttonLink}
                    buttonText={control.value.buttonText}
                    buttonTarget={control.value.buttonTarget}
                />
            );
        },
    },
    {
        name: 'leadFamlyGameEmbed',
        markup: (props) => {
            const { control } = props;

            return <LeadFamlyGameEmbed id={control.value.id} />;
        },
    },
    {
        name: 'maternityCalculatorView',
        markup: (props) => {
            const { control } = props;
            return (
                <MaternityCalculator useNewRules={control.value.useNewRules} />
            );
        },
    },
    {
        name: 'searchField',
        markup: (props) => {
            const { index, control } = props;

            return (
                <>
                    <Grid container justify="center">
                        <Grid item xs={12} sm={6}>
                            <SearchField
                                name={'searchField' + index}
                                placeholder={control.value.placeholderText}
                                defaultValue=""
                                onClick={() => {
                                    const inputElement =
                                        document.getElementsByName(
                                            'searchField' + index
                                        )[0] as HTMLInputElement;
                                    window.location.href =
                                        control.value.searchUrl +
                                        '&cludoquery=' +
                                        inputElement.value;
                                }}
                                onKeyUp={(event) => {
                                    const charCode = event.which
                                        ? event.which
                                        : event.keyCode;

                                    // If enter key was pressed
                                    if (charCode === 13) {
                                        const inputElement =
                                            document.getElementsByName(
                                                'searchField' + index
                                            )[0] as HTMLInputElement;
                                        window.location.href =
                                            control.value.searchUrl +
                                            '&cludoquery=' +
                                            inputElement.value;
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </>
            );
        },
    },
];

const handleItemClick = (item: any) => {
    dataLayer.trackInteractionEvent(
        'ida.interaction',
        'jobfinder',
        'click through',
        item.Href
    );
};
const handleReadMoreClick = (button: any) => {
    dataLayer.trackInteractionEvent(
        'ida.interaction',
        'jobfinder',
        'click through',
        button.href
    );
};

export default LocalComponentMap;
