import { Button, Heading, Paper } from '@ingeniorforeningen/design-system';
import * as React from 'react';
import injectSheet, { StyleSheetThemed } from 'react-jss';

export interface IEntranceBoxProps {
    heading: string;
    buttonText?: string;
    buttonHref?: string;
    phoneNumber?: string;
    email?: string;
    contactUrl?: string;
    classes?: any;
}

const styles: StyleSheetThemed = (theme) => ({
    entranceBox: {
        paddingTop: '30px',
        paddingBottom: '30px',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px',
        height: '160px',
    },

    contactEntity: {
        color: theme.colors.neutralBlack,
        display: 'block',
        fontFamily: theme.typography.fontPrimary,
        fontSize: '24px',
        lineHeight: '29px',
        fontWeight: 600,
        textDecoration: 'none',
    },

    entranceBoxHeading: {
        fontFamily: theme.typography.fontPrimary,
        fontSize: '16px',
        lineHeight: '19px',
        fontWeight: 600,
        marginTop: '6px',
        marginBottom: '18px',
    },
});

class EntranceBox<T extends IEntranceBoxProps> extends React.Component<T, {}> {
    props: any;
    public render() {
        return (
            <Paper alignCenter className={this.props.classes.entranceBox}>
                <Heading
                    is="h4"
                    className={this.props.classes.entranceBoxHeading}
                >
                    {this.props.heading}
                </Heading>

                {Boolean(this.props.phoneNumber) && (
                    <a
                        href={`tel:${
                            this.props.phoneNumber
                                ? this.props.phoneNumber.replace(/\s/g, '')
                                : ''
                        }`}
                        className={this.props.classes.contactEntity}
                    >
                        {this.props.phoneNumber}
                    </a>
                )}
                {Boolean(this.props.email) && (
                    <a
                        href={this.props.contactUrl}
                        className={this.props.classes.contactEntity}
                    >
                        {this.props.email}
                    </a>
                )}
                {Boolean(this.props.buttonText) && (
                    <Button
                        component="a"
                        href={this.props.buttonHref}
                        buttonType="primary"
                        version="cta"
                        noMargin
                    >
                        {this.props.buttonText}
                    </Button>
                )}
            </Paper>
        );
    }
}

export default injectSheet(styles)(EntranceBox);
