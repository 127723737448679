import React, { useEffect } from 'react';
import { Grid } from '@ingeniorforeningen/design-system';
import injectSheet, { StyleSheetThemed } from 'react-jss';

export type TFloatingGridProps = {
    appear: boolean;
    children: React.ReactNode;
    classes?: any;
    lg?:
        | false
        | 'auto'
        | true
        | 1
        | 2
        | 3
        | 4
        | 5
        | 6
        | 7
        | 8
        | 9
        | 10
        | 11
        | 12;
    md?:
        | false
        | 'auto'
        | true
        | 1
        | 2
        | 3
        | 4
        | 5
        | 6
        | 7
        | 8
        | 9
        | 10
        | 11
        | 12;
    sm?:
        | false
        | 'auto'
        | true
        | 1
        | 2
        | 3
        | 4
        | 5
        | 6
        | 7
        | 8
        | 9
        | 10
        | 11
        | 12;
    xl?:
        | false
        | 'auto'
        | true
        | 1
        | 2
        | 3
        | 4
        | 5
        | 6
        | 7
        | 8
        | 9
        | 10
        | 11
        | 12;
    xs?:
        | false
        | 'auto'
        | true
        | 1
        | 2
        | 3
        | 4
        | 5
        | 6
        | 7
        | 8
        | 9
        | 10
        | 11
        | 12;
};

const styles: StyleSheetThemed = (theme) => ({
    floatingGridWrapper: {
        padding: '0',
        position: 'fixed',
        zIndex: '999',
        transition: 'transform 0.7s, opacity 0.3s',
        willChange: 'transform, opacity',
    },
    contentWrapper: {
        display: 'none',
        position: 'fixed',
        '& > div': {
            marginBottom: '1em',
        },
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    dissappear: {
        transform: 'translateX(50vw)',
        opacity: 0,
    },
    appear: {
        transform: 'translateX(0vw)',
        opacity: 1,
    },
});

const FloatingGrid: React.FC<TFloatingGridProps> = ({
    appear,
    children,
    classes,
    lg,
    md,
    sm,
    xl,
    xs,
}) => {
    return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
            <div
                className={`${classes.floatingGridWrapper} ${
                    appear ? classes.appear : classes.dissappear
                }`}
            >
                <div className={classes.contentWrapper}>{children}</div>
            </div>
        </Grid>
    );
};

export default injectSheet(styles)(FloatingGrid);
