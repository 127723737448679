import {
    Grid,
    Heading,
    Page,
    Richtext,
    TextMouseParallax,
} from '@ingeniorforeningen/design-system';
import * as React from 'react';
import { track } from '../Analytics';

export interface IPageNotFoundProps {
    Header: string;
    Teaser: string;
    Text: string;
    ErrorCode: string;
}

const letters = [
    {
        letter: '4',
        velocity: 1.5,
        initialRotation: 24,
    },
    {
        letter: '0',
        velocity: 5,
        yOffset: 57,
        initialRotation: -9,
    },
    {
        letter: '4',
        velocity: 3,
        yOffset: 16,
        initialRotation: -18,
    },
];

class PageNotFound extends React.Component<IPageNotFoundProps> {
    public componentDidMount() {
        track().general.exception({
            errorcode: 404,
        });
    }

    public render() {
        if (this.props.ErrorCode == '400') {
            letters[2].letter = '0';
        }
        return (
            <Page condensed>
                <Grid container>
                    <Grid item xs={10} style={{ textAlign: 'center' }}>
                        <TextMouseParallax letters={letters} />
                    </Grid>
                    <Grid item xs={10} style={{ textAlign: 'center' }}>
                        <Heading
                            size={600}
                            style={{ marginTop: 0, marginBottom: '10px' }}
                        >
                            {this.props.Header}
                        </Heading>
                        <Heading
                            size={200}
                            style={{ marginTop: 0, marginBottom: 0 }}
                        >
                            {this.props.Teaser}
                        </Heading>
                    </Grid>
                    <Grid
                        item
                        xs={10}
                        style={{ textAlign: 'left', paddingTop: 0 }}
                    >
                        <Richtext>{this.props.Text}</Richtext>
                    </Grid>
                </Grid>
            </Page>
        );
    }
}

export default PageNotFound;
