import { Button, Link } from '@ingeniorforeningen/design-system';
import * as React from 'react';
import injectSheet, { StyleSheetThemed } from 'react-jss';
import { MeetupStatus } from '../../Enums/MeetupStatus';
import { track } from '../../Analytics';
import { IMeetupPageProps } from '../../Pages/MeetupPage';
import { ToEcommerceObject } from '../../Analytics/helpers';

export interface ISignupButtonProps {
    meetup?: IMeetupPageProps;
    id: number;
    statusCode: MeetupStatus;
    classes: any;
    signupUrl: string;
    helpText: any;
    meetupType: string;
}

const styles: StyleSheetThemed = (theme) => ({
    center: {
        display: 'flex',
        width: '100%',
        '& button': {
            margin: 'auto',
        },
    },
    buttonWrapperStyling: {
        display: 'flex',
        'flex-direction': 'column',
        width: '100%',
    },
    buttonWrapperButtonStyling: {
        textAlign: 'center',
    },
    disabledButtonStyling: {
        borderColor: '#2F4F4F',
        transition: 'none',
        cursor: 'not-allowed',
        transform: 'inherit',
    },
    noTextDecoration: {
        textDecoration: 'none',
    },
});

class SignupButton extends React.Component<ISignupButtonProps, any> {
    constructor(props) {
        super(props);
    }

    private signupEnabledVariant = (buttonTitle) => {
        return (
            <div
                className={`${this.props.classes.buttonWrapperStyling} ${this.props.classes.center}`}
            >
                {this.props.statusCode === MeetupStatus.SoldOut && (
                    <p style={{ fontWeight: 600 }}>Udsolgt</p>
                )}
                <Link
                    url={this.props.signupUrl}
                    className={`${this.props.classes.noTextDecoration} ${this.props.classes.buttonWrapperButtonStyling}`}
                >
                    <Button
                        buttonType="primary"
                        onClick={() =>
                            this.props.meetup
                                ? track().ecommerce.addToCart(
                                      ToEcommerceObject(
                                          this.props.meetup,
                                          this.props.meetupType
                                      )
                                  )
                                : null
                        }
                        version="cta"
                        noMargin
                        wide
                    >
                        {buttonTitle}
                    </Button>
                </Link>
                <div className={this.props.classes.buttonWrapperButtonStyling}>
                    {this.props.helpText}
                </div>
            </div>
        );
    };

    private signupDisabledVariant = (buttonTitle) => {
        return (
            <div className={this.props.classes.center}>
                <Button
                    buttonType="secondary"
                    noMargin
                    disabled
                    wide
                    className={this.props.classes.disabledButtonStyling}
                >
                    {buttonTitle}
                </Button>
            </div>
        );
    };

    private signupButton = () => {
        if (this.props.statusCode === MeetupStatus.HasBeenHeld) {
            return this.signupDisabledVariant(
                this.props.meetupType + ' er afholdt'
            );
        }

        if (this.props.statusCode === MeetupStatus.Cancelled) {
            return this.signupDisabledVariant(
                this.props.meetupType + ' er aflyst'
            );
        }

        if (this.props.statusCode === MeetupStatus.SoldOut) {
            return this.signupDisabledVariant(
                this.props.meetupType + ' er udsolgt'
            );
        }

        if (this.props.statusCode === MeetupStatus.LastEntryDatePassed) {
            return this.signupDisabledVariant(
                'Tilmeldingsfristen er overskredet'
            );
        }

        if (this.props.statusCode === MeetupStatus.WaitingList) {
            return this.signupEnabledVariant('Tilmeld venteliste');
        }

        return this.signupEnabledVariant('Tilmeld');
    };

    public render() {
        return this.signupButton();
    }
}

export default injectSheet(styles)(SignupButton);
