import * as React from 'react';
import {
    Grid,
    VideoPlayer,
    Button,
    Heading,
    Paragraph,
} from '@ingeniorforeningen/design-system';
import injectSheet, { StyleSheetThemed } from 'react-jss';

type TMediaCta = {
    mediaUrl: string;
    mediaType: 'video' | 'podcast';
    header: string;
    description: string;
    buttonLink: string;
    buttonText: string;
    classes: any;
    buttonTarget: string;
};

const styles: StyleSheetThemed = (theme) => ({
    mediaWrapper: {
        width: '100%',
    },
    fullWidth: {
        width: '100%',
    },
    spacing: {
        paddingTop: '0px!important',
        marginTop: '0px!important',
    },
    margin: {
        marginBottom: '100px',
    },
    '@media (min-width: 600px)': {
        spacing: {
            paddingTop: '0px!important',
            marginTop: '16.6px!important', // margin from Grid component
        },
    },
});

const MediaCta: React.FC<TMediaCta> = ({
    classes,
    mediaUrl,
    mediaType,
    header,
    description,
    buttonLink,
    buttonTarget,
    buttonText,
}) => {
    return (
        <Grid container className={classes.margin}>
            <Grid item sm={6} className={classes.fullWidth}>
                <div className={classes.mediaWrapper}>
                    <VideoPlayer url={mediaUrl} type={mediaType} />
                </div>
            </Grid>
            <Grid item sm={4} className={classes.spacing}>
                <Heading size={300} className={classes.spacing}>
                    {header}
                </Heading>
                <Paragraph dangerouslySetInnerHTML={{ __html: description }}>
                    {}
                </Paragraph>
                <Button
                    buttonType="primary"
                    component="a"
                    href={buttonLink}
                    target={buttonTarget}
                >
                    {buttonText}
                </Button>
            </Grid>
        </Grid>
    );
};
export default injectSheet(styles)(MediaCta);
