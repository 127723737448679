import * as React from 'react';
import { useState } from 'react';
import { Breadcrumb } from '@ingeniorforeningen/design-system';
import injectSheet, { StyleSheetThemed } from 'react-jss';
import classNames from 'classnames';

type TBreadcrumbWrapper = {
    breadcrumbs: any[];
    classes?: any;
};

const styles: StyleSheetThemed = (theme) => ({
    root: {
        display: 'none',
    },
    breadcrumbWrapper: {
        display: 'block',

        [theme.breakpoints.up(960)]: {
            padding: '20px 40px',
        },
    },
});

const BreadcrumbWraper: React.FC<TBreadcrumbWrapper> = ({
    classes,
    breadcrumbs,
}) => {
    const [visible, setVisible] = useState<boolean>();
    const [breadcrumbWrapperClass, setBreadcrumbWrapperClass] = useState();

    React.useEffect(() => {
        setBreadcrumbWrapperClass(
            classNames(classes.root, {
                [classes.breadcrumbWrapper]: visible,
            })
        );
    }, [visible]);

    return (
        <div className={breadcrumbWrapperClass}>
            <Breadcrumb
                breadcrumbs={breadcrumbs}
                onVisibleChange={setVisible}
            />
        </div>
    );
};
export default injectSheet(styles)(BreadcrumbWraper);
