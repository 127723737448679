import React from 'react';
import { Icons } from '@ingeniorforeningen/design-system';

const sustainableDevelopmentGoalsIcons = (list: number[], language: string) => {
    if (!list || !language) {
        return [];
    }
    return list.map((sdg, index) => {
        if (language == 'engelsk') {
            switch (sdg) {
                case 1:
                    return <Icons.SDG1en />;
                case 2:
                    return <Icons.SDG2en />;
                case 3:
                    return <Icons.SDG3en />;
                case 4:
                    return <Icons.SDG4en />;
                case 5:
                    return <Icons.SDG5en />;
                case 6:
                    return <Icons.SDG6en />;
                case 7:
                    return <Icons.SDG7en />;
                case 8:
                    return <Icons.SDG8en />;
                case 9:
                    return <Icons.SDG9en />;
                case 10:
                    return <Icons.SDG10en />;
                case 11:
                    return <Icons.SDG11en />;
                case 12:
                    return <Icons.SDG12en />;
                case 13:
                    return <Icons.SDG13en />;
                case 14:
                    return <Icons.SDG14en />;
                case 15:
                    return <Icons.SDG15en />;
                case 16:
                    return <Icons.SDG16en />;
                case 17:
                    return <Icons.SDG17en />;
            }
        } else {
            switch (sdg) {
                case 1:
                    return <Icons.SDG1da />;
                case 2:
                    return <Icons.SDG2da />;
                case 3:
                    return <Icons.SDG3da />;
                case 4:
                    return <Icons.SDG4da />;
                case 5:
                    return <Icons.SDG5da />;
                case 6:
                    return <Icons.SDG6da />;
                case 7:
                    return <Icons.SDG7da />;
                case 8:
                    return <Icons.SDG8da />;
                case 9:
                    return <Icons.SDG9da />;
                case 10:
                    return <Icons.SDG10da />;
                case 11:
                    return <Icons.SDG11da />;
                case 12:
                    return <Icons.SDG12da />;
                case 13:
                    return <Icons.SDG13da />;
                case 14:
                    return <Icons.SDG14da />;
                case 15:
                    return <Icons.SDG15da />;
                case 16:
                    return <Icons.SDG16da />;
                case 17:
                    return <Icons.SDG17da />;
            }
        }
    });
};

export default sustainableDevelopmentGoalsIcons;
