import { Grid, Page, PageHero } from '@ingeniorforeningen/design-system';
import * as React from 'react';
import UmbracoGrid from '../UmbracoGrid';

export interface IMeetupFrontpageProps {
    Header: string;
    Teaser: string;
    TypeFacets: any;
    LocationFacets: any;
    GridContainer: any;
    Id: number;
    ShowEditLink: boolean;
}

export interface IMeetupFrontpageState {
    formRef: any;
}

class MeetupFrontpage extends React.Component<
    IMeetupFrontpageProps,
    IMeetupFrontpageState
> {
    constructor(props) {
        super(props);
        this.state = {
            formRef: React.createRef(),
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
    }

    public handleSubmit(event) {
        this.state.formRef.current.submit();
    }

    public onKeyUp(event) {
        const charCode = event.which ? event.which : event.keyCode;

        // If enter key was pressed
        if (charCode === 13) {
            this.state.formRef.current.submit();
        }
    }

    public render() {
        return (
            <Page condensed>
                <Grid container narrow>
                    <Grid item xs={12} md={8}>
                        <PageHero
                            header={this.props.Header}
                            editPage={{
                                show: this.props.ShowEditLink,
                                nodeId: this.props.Id,
                            }}
                            teaser={this.props.Teaser}
                        />
                    </Grid>
                </Grid>
                <UmbracoGrid data={this.props.GridContainer} {...this.props} />
            </Page>
        );
    }
}

export default MeetupFrontpage;
