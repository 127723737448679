import {
    AnchorMenu,
    ArticleDateReadingTime,
    BadgeLink,
    Grid,
    Page,
    PageHero,
    TopCoverImage,
} from '@ingeniorforeningen/design-system';

import classNames from 'classnames';
import * as React from 'react';
import injectSheet, { StyleSheetThemed } from 'react-jss';
import UmbracoGrid from '../UmbracoGrid';
import { FeatureFlag, IFeatureFlags } from '../Components/FeatureFlag';
import RelatedContent, { IRelatedContent } from '../Components/RelatedContent';
import { Newsletter, INewsletter } from '../Components/Newsletter';

export interface IThemeTag {
    Url: string;
    Title: string;
    ImageUrl: string;
    ImageAltText: string;
}

export interface IAuthor {
    Name: string;
    Image: string;
}

export interface IAnchorMenuItem {
    Label: string;
    Hash: string;
}

export interface IArticlePageProps {
    Date: string;
    modifyDate: Date;
    Author: IAuthor;
    ReadingTime: number;
    Theme: IThemeTag;
    Header: string;
    Teaser: string;
    IntroImage: string;
    GridContainer: any;
    AnchorMenu: IAnchorMenuItem[];
    classes?: any;
    Id: number;
    ShowEditLink: boolean;
    FeatureFlags: IFeatureFlags;
    RelatedContent: IRelatedContent;
    NewsletterSpotBox: INewsletter;
}

const styles: StyleSheetThemed = (theme) => ({
    badgeLink: {
        paddingTop: '20px',
    },

    badgeLinkOnTopImage: {
        marginTop: '-43px',
        [theme.breakpoints.up('sm')]: {
            paddingTop: '20px',
            marginTop: 0,
        },
    },
});

class ArticlePage extends React.Component<IArticlePageProps> {
    private static defaultProps: Partial<IArticlePageProps> = {
        Author: {
            Image: '',
            Name: '',
        },
    };

    private themeTagClassName = classNames({
        [this.props.classes.themeTag]: Boolean(this.props.IntroImage) === false,
        [this.props.classes.themeTagOnIntroImage]: Boolean(
            this.props.IntroImage
        ),
    });

    public render() {
        let tag;

        const { Theme, IntroImage, Author, classes } = this.props;

        const anchorMenu = this.props.AnchorMenu
            ? this.props.AnchorMenu.map((item, index) => {
                  return {
                      href: item.Hash,
                      title: item.Label,
                  };
              })
            : [];

        const renderThemeTag = Theme != null && Theme.Title != null;

        if (renderThemeTag) {
            tag = (
                <BadgeLink
                    href={Theme.Url}
                    icon={Theme.ImageUrl}
                    iconAlt={Theme.ImageAltText}
                    title={Theme.Title}
                    className={
                        Boolean(this.props.IntroImage)
                            ? classes.badgeLinkOnTopImage
                            : classes.badgeLink
                    }
                    withBreadCrumb
                />
            );
        }
        return (
            <React.Fragment>
                <FeatureFlag show={this.props.FeatureFlags.ShowAnchorMenu}>
                    <AnchorMenu items={anchorMenu} />
                </FeatureFlag>

                <Page
                    hasAnchorMenu={this.hasAnchorMenu()}
                    hasTopImage={Boolean(this.props.IntroImage)}
                >
                    {IntroImage && <TopCoverImage src={IntroImage} />}
                    <Grid container spacing={40}>
                        <Grid item condensed xs={12} sm={3}>
                            {tag}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ArticleDateReadingTime
                                date={this.props.Date}
                                time={this.props.ReadingTime}
                            />
                        </Grid>
                        <Grid item sm={3} />
                    </Grid>
                    <Grid container spacing={0}>
                        <Grid item condensed sm={2} />
                        <Grid item condensed xs={12} sm={8}>
                            <PageHero
                                header={this.props.Header}
                                editPage={{
                                    show: this.props.ShowEditLink,
                                    nodeId: this.props.Id,
                                }}
                                teaser={this.props.Teaser}
                                author={{
                                    image: Author.Image,
                                    author: Author.Name,
                                }}
                            />
                        </Grid>
                        <Grid item condensed sm={2} />
                    </Grid>
                    <UmbracoGrid data={this.props.GridContainer} />
                    {Boolean(this.props.NewsletterSpotBox.Header) && (
                        <Newsletter {...this.props.NewsletterSpotBox} />
                    )}
                    <RelatedContent
                        relatedContent={this.props.RelatedContent}
                        showEditLink={this.props.ShowEditLink}
                    />
                </Page>
            </React.Fragment>
        );
    }

    private hasAnchorMenu = () => {
        return (
            this.props.AnchorMenu &&
            this.props.AnchorMenu.length &&
            this.props.FeatureFlags.ShowAnchorMenu
        );
    };
}

export default injectSheet(styles)(ArticlePage);
