import {
    AnchorMenu,
    Button,
    ContactBand,
    DocumentsAndLinks,
    Grid,
    Heading,
    Page,
    PageSection,
    Paragraph,
    Richtext,
    SectionHeader,
    SwimlaneCluster,
    TCompositeTileProps,
    TDocumentsAndLinksProps,
    TopCoverImage,
} from '@ingeniorforeningen/design-system';
import * as React from 'react';
import injectSheet, { StyleSheetThemed } from 'react-jss';
import dataLayer from '../Analytics/DataLayer';
import EditPageButton from '../Components/EditPageButton';
import { FeatureFlag, IFeatureFlags } from '../Components/FeatureFlag';
import UmbracoGrid from '../UmbracoGrid';
import { Newsletter, INewsletter } from '../Components/Newsletter';
import { IMeetupPageProps } from './MeetupPage';
import { ToEcommerceList, ToEcommerceObject } from '../Analytics/helpers';
import { track } from '../Analytics';

export interface IListItem {
    Caption: string;
    Value: string;
}

export interface IContact {
    Image: string;
    Name: string;
    Occupation: string;
    Phone: string;
    Email: string;
    IsValid: boolean;
}

export interface IAnchorMenuItem {
    Label: string;
    Hash: string;
}

export interface INetworkPageProps {
    Header: string;
    Teaser: string;
    IntroImage: string;
    DescriptionHeadline: string;
    Description: string | React.ReactNode; // RichText
    Prices: IListItem[];
    JoinNetworkAs: IListItem[];
    NewsletterSpotBox: INewsletter;
    Contact: IContact;
    DocumentsAndLinks: TDocumentsAndLinksProps;
    ShowDocumentsAndLinks: boolean;
    UpcomingMeetups: any;
    Meetups?: IMeetupPageProps[];
    SwimLaneLink: string;
    AnchorMenu: IAnchorMenuItem[];
    Id: number;
    ShowEditLink: boolean;
    FeatureFlags: IFeatureFlags;
    classes?: any;
    GridContainer: any;
}

function UpcomingMeetups(props) {
    const upcomingMeetups = props.items.map((meetup) => {
        const ecommerceObject = ToEcommerceObject(
            meetup.Meetup,
            'kursus',
            'fagteknisknetværksside'
        );
        return {
            city: meetup.City,
            imageUrl: meetup.ImageUrl,
            monthAndYear: meetup.MonthAndYear,
            day: meetup.Day,
            date: meetup.Date,
            tag: 'Arrangement',
            title: meetup.Title,
            href: meetup.Href,
            onItemClicked: () => track().ecommerce.selectItem(ecommerceObject),
        };
    }) as TCompositeTileProps[];

    if (props.seeAllLink === '') {
        return (
            <SwimlaneCluster
                heading={`Arrangementer for ${props.header}`}
                items={upcomingMeetups}
                condensed
            />
        );
    }
    return (
        <SwimlaneCluster
            heading={`Arrangementer for ${props.header}`}
            showAllHeading="Vis alle"
            showAllUrl={props.seeAllLink}
            items={upcomingMeetups}
            condensed
        />
    );
}

const styles: StyleSheetThemed = (theme) => ({});

class NetworkPage extends React.Component<INetworkPageProps> {
    componentDidMount() {
        const upcomingMeetups = this.props.UpcomingMeetups?.map(
            (m) => m.Meetup
        ) as IMeetupPageProps[];

        const meetups = ToEcommerceList(
            'kursus',
            upcomingMeetups,
            'fagteknisknetværksside'
        );

        if (meetups && meetups.length > 0) {
            track().ecommerce.viewItemList(meetups);
        }
    }

    public render() {
        const { IntroImage } = this.props;
        const newsletterSpotBox = this.props.NewsletterSpotBox;
        const gridpadding = { padding: '0 20px' };
        const anchorMenu = this.props.AnchorMenu
            ? this.props.AnchorMenu.map((item, index) => {
                  return {
                      href: item.Hash,
                      title: item.Label,
                  };
              })
            : [];

        return (
            <React.Fragment>
                <FeatureFlag show={this.props.FeatureFlags.ShowAnchorMenu}>
                    <AnchorMenu items={anchorMenu} />
                </FeatureFlag>

                <Page
                    condensed
                    hasAnchorMenu={this.hasAnchorMenu()}
                    hasTopImage={Boolean(this.props.IntroImage)}
                >
                    {IntroImage && <TopCoverImage src={IntroImage} />}
                    <PageSection alternate>
                        <Grid container>
                            <Grid item sm={1} />
                            <Grid item xs={12} sm={5} condensed>
                                <Grid item xs={12}>
                                    <Heading is="h1" noMargin size={600}>
                                        {this.props.Header}{' '}
                                        <EditPageButton
                                            showEditLink={
                                                this.props.ShowEditLink
                                            }
                                            nodeId={this.props.Id}
                                        />
                                    </Heading>
                                    <Paragraph half>
                                        {this.props.Teaser}
                                    </Paragraph>
                                </Grid>
                            </Grid>
                            <Grid item sm={2} />
                            <Grid item xs={12} sm={4} condensed slim>
                                <div>
                                    {this.props.Prices.length !== 0 && (
                                        <Heading size={100}>Pris</Heading>
                                    )}
                                    {this.props.Prices.map((price, index) => {
                                        return (
                                            <Grid
                                                container
                                                style={{
                                                    padding: 0,
                                                    marginBottom: '10px',
                                                }}
                                                xs={12}
                                                spacing={0}
                                                key={index}
                                            >
                                                <Grid item xs={6}>
                                                    {price.Caption}
                                                </Grid>
                                                <Grid item xs={6}>
                                                    {price.Value}
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </div>
                            </Grid>
                            <Grid item xs={1} />
                            <Grid
                                container
                                direction="column"
                                xs={12}
                                alignItems="center"
                            >
                                {this.props.JoinNetworkAs.map((link, index) => {
                                    return (
                                        <Button
                                            style={{
                                                marginLeft: '16px',
                                                marginRight: '16px',
                                                maxWidth: 'calc(100% - 32px)',
                                                width: '410px',
                                            }}
                                            onClick={() =>
                                                dataLayer.trackEvent(
                                                    'ida.microConversion',
                                                    'network signup',
                                                    this.props.Header
                                                )
                                            }
                                            version="cta"
                                            component={'a'}
                                            href={link.Value}
                                            key={index}
                                        >
                                            {link.Caption}
                                        </Button>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </PageSection>

                    {this.props.UpcomingMeetups && (
                        <PageSection>
                            <SectionHeader hash="arrangementer" invisible>
                                Arrangementer
                            </SectionHeader>
                            <UpcomingMeetups
                                meetups={this.props.Meetups}
                                items={this.props.UpcomingMeetups}
                                header={this.props.Header}
                                seeAllLink={this.props.SwimLaneLink}
                            />
                        </PageSection>
                    )}

                    <Grid
                        container
                        spacing={0}
                        alignItems="flex-start"
                        direction="row"
                        justify="center"
                    >
                        <Grid item sm={6} style={{ textAlign: 'left' }}>
                            <SectionHeader hash="om-netvaerket">
                                {this.props.DescriptionHeadline}
                            </SectionHeader>
                            <Richtext>{this.props.Description}</Richtext>
                        </Grid>
                    </Grid>

                    <UmbracoGrid
                        condensed
                        data={this.props.GridContainer}
                        {...this.props}
                    />
                    {Boolean(newsletterSpotBox.Header) && (
                        <Newsletter {...newsletterSpotBox} />
                    )}
                    {Boolean(this.props.ShowDocumentsAndLinks) && (
                        <PageSection alternate>
                            <Grid container>
                                <SectionHeader
                                    hash="dokumenter-og-links"
                                    invisible
                                >
                                    Dokumenter og links
                                </SectionHeader>
                                <DocumentsAndLinks
                                    {...this.props.DocumentsAndLinks}
                                    style={{ paddingTop: 0 }}
                                />
                            </Grid>
                        </PageSection>
                    )}
                </Page>
                {Boolean(this.props.Contact.IsValid) && (
                    <PageSection>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <SectionHeader
                                    hash="vil-du-vide-mere"
                                    invisible
                                >
                                    Vil du vide mere
                                </SectionHeader>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                            <ContactBand {...this.props.Contact} />
                        </Grid>
                    </PageSection>
                )}
            </React.Fragment>
        );
    }

    private hasAnchorMenu = () => {
        return (
            this.props.AnchorMenu &&
            this.props.AnchorMenu.length &&
            this.props.FeatureFlags.ShowAnchorMenu
        );
    };
}

export default injectSheet(styles)(NetworkPage);
