import {
    Button,
    Grid,
    GridHeader,
    Link,
    Paper,
} from '@ingeniorforeningen/design-system';
import * as React from 'react';
import injectSheet, { StyleSheetThemed } from 'react-jss';

export interface IReadMoreButtonProps {
    label: string;
    href: string;
    newTab?: boolean;
}

export interface IJobProps {
    RecruiterLogoUrl: string;
    Title: string;
    RecruiterName: string;
    LocationDescription: string;
    Href: string;
    Type: string;
}

export interface IJobListProps {
    heading?: string;
    items: IJobProps[];
    classes?: any;
    readMoreButton?: IReadMoreButtonProps;
    handleOnItemClick?: (val: any) => void;
    handleOnReadMoreLinkClick?: (val: any) => void;
}

const styles: StyleSheetThemed = (theme) => ({
    gridHeader: {
        marginBottom: '20px',

        [theme.breakpoints.up('sm')]: {
            marginBottom: '40px',
        },
    },

    jobLink: {
        display: 'block',
        textDecoration: 'none',
        width: '100%',
        marginBottom: '20px',

        [theme.breakpoints.up('sm')]: {
            width: 'calc(50% - 40px / 2)',
            marginBottom: '40px',
            marginRight: '40px',
            '&:nth-child(2n)': {
                marginRight: 0,
            },
        },
    },

    jobBox: {
        padding: '30px 60px',
        minHeight: '300px',
    },

    jobRecruiterLogo: {
        display: 'block',
        width: 'auto',
        height: '80px',
        margin: '0 auto 24px auto',

        [theme.breakpoints.up('sm')]: {
            margin: '0 0 20px 0',
        },
    },

    jobTitle: {
        color: theme.colors.neutralBlack,
        fontFamily: theme.typography.fontPrimary,
        fontSize: '16px',
        lineHeight: '30px',
        textAlign: 'center',
        fontWeight: 600,
        marginTop: 0,
        marginBottom: 0,

        [theme.breakpoints.up('sm')]: {
            textAlign: 'left',
        },
    },

    jobDescription: {
        color: theme.colors.neutralBlack,
        fontFamily: theme.typography.fontPrimary,
        fontSize: '16px',
        lineHeight: '30px',
        textAlign: 'center',
        fontWeight: 'normal',
        marginTop: 0,
        marginBottom: 0,

        [theme.breakpoints.up('sm')]: {
            textAlign: 'left',
        },
    },

    centered: {
        display: 'flex',
        margin: '0 auto',
        maxWidth: '250px',
        marginBottom: '80px',
    },
});

class JobList<T extends IJobListProps> extends React.Component<T, {}> {
    props: any;
    public render() {
        return (
            <React.Fragment>
                {Boolean(this.props.heading) && (
                    <GridHeader
                        className={this.props.classes.gridHeader}
                        heading={this.props.heading as string}
                        fullWidth
                    />
                )}

                {Boolean(this.props.items) && Boolean(this.props.items.length) && (
                    <Grid container narrow>
                        {this.props.items.map((item, index) => (
                            <Link
                                key={`jobLink${index}`}
                                url={item.Href}
                                target="_blank"
                                className={this.props.classes.jobLink}
                                // tslint:disable-next-line:no-unused-expression
                                onClick={() => {
                                    this.props.handleOnItemClick &&
                                        this.props.handleOnItemClick(item);
                                }}
                            >
                                <Paper
                                    className={this.props.classes.jobBox}
                                    hover
                                >
                                    <img
                                        className={
                                            this.props.classes.jobRecruiterLogo
                                        }
                                        src={item.RecruiterLogoUrl}
                                        alt={`${item.RecruiterName} logo`}
                                    />

                                    <p className={this.props.classes.jobTitle}>
                                        {item.Title}
                                    </p>
                                    <p
                                        className={
                                            this.props.classes.jobDescription
                                        }
                                    >
                                        {item.RecruiterName} -{' '}
                                        {item.LocationDescription}
                                    </p>
                                    <p
                                        className={
                                            this.props.classes.jobDescription
                                        }
                                    >
                                        {item.Type}
                                    </p>
                                </Paper>
                            </Link>
                        ))}
                    </Grid>
                )}

                {Boolean(this.props.readMoreButton) && (
                    <Button
                        component="a"
                        buttonType="secondary"
                        href={this.props.readMoreButton!.href}
                        className={this.props.classes.centered}
                        target={
                            Boolean(this.props.readMoreButton!.newTab)
                                ? '_blank'
                                : ''
                        }
                        // tslint:disable-next-line:no-unused-expression
                        onClick={() => {
                            this.props.handleOnReadMoreLinkClick &&
                                this.props.handleOnReadMoreLinkClick(
                                    this.props.readMoreButton
                                );
                        }}
                    >
                        {this.props.readMoreButton!.label}
                    </Button>
                )}
            </React.Fragment>
        );
    }
}

export default injectSheet(styles)(JobList);
