import React from 'react';

type TMaternityCalculator = {
    useNewRules: boolean;
};

const MaternityCalculator: React.FC<TMaternityCalculator> = ({ useNewRules }) => {
    const ref = React.useRef<HTMLIFrameElement>(null);

    const heightHandler = event => {
        if (ref.current && event.data.event_id === 'vas-height') {
            ref.current.style.height = event.data.data.height + 'px';
        }
    };

    const getUrlParams = name => {
        var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(
            window.location.href
        );
        if (results == null) {
            return null;
        } else {
            return decodeURI(results[1]) || 0;
        }
    };

    const calculatorUrl = useNewRules ? '//barselsberegnerdk.viscom.ida.dk' : '//barselsberegnerdk-gamle-regler.viscom.ida.dk';

    React.useEffect(() => {
        window.addEventListener('message', heightHandler);

        var muid = getUrlParams('uid');
        if (muid !== null && ref.current) {
            ref.current.setAttribute(
                'src',
                '//barselsberegnerdk.viscom.ida.dk/fetch.php?uid=' + muid
            );
        }

        return () => window.removeEventListener('message', heightHandler);
    }, []);

    return (
        <iframe
            src={calculatorUrl}
            scrolling="no"
            frameBorder="0"
            id={useNewRules ? 'newMaternity' : 'oldMaternity'}
            style={{ height: 'auto', width: '100%' }}
            ref={ref}
        />
    );
};

export default MaternityCalculator;
