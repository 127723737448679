import { CMSGrid } from '@ingeniorforeningen/design-system';
import * as React from 'react';
import ComponentMap from './ComponentMap';

export interface IUmbracoGridProps {
    condensed?: boolean;
    data: any;
}

class UmbracoGrid extends React.Component<IUmbracoGridProps> {
    private static defaultProps = {
        component: 'div',
    };
    props: any;

    public render() {
        const { ...other } = this.props;

        return <CMSGrid componentMap={ComponentMap} {...this.props} />;
    }
}

export default UmbracoGrid;
