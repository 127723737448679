import {
    Datepicker,
    Grid,
    Select,
    TextField,
} from '@ingeniorforeningen/design-system';
import * as React from 'react';

export interface IGenericOverviewPageProps {
    Text: string;
}

class GenericOverviewPage extends React.Component<IGenericOverviewPageProps> {
    public render() {
        return (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={3}>
                        <TextField
                            name="name"
                            label={`Something`}
                            value={`Something`}
                            error={``}
                            onChange={() => {}}
                            onBlur={() => {}}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <Datepicker
                            name="birthdate"
                            label={`Something 2`}
                            value={``}
                            placeholder={`Vælg lige en dato kammerat`}
                            error={``}
                            onChange={() => {}}
                            onBlur={() => {}}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <TextField
                            name="address"
                            label={`Something 3`}
                            value={`Vælg`}
                            error={``}
                            onChange={() => {}}
                            onBlur={() => {}}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <TextField
                            name="postalCode"
                            label={`Something 4`}
                            value={``}
                            error={``}
                            onChange={() => {}}
                            onBlur={() => {}}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <TextField
                            name="city"
                            label={`Something 5`}
                            value={``}
                            error={``}
                            onChange={() => {}}
                            onBlur={() => {}}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <Select
                            name={`johnny`}
                            placeholder={`Vælg lige noget`}
                            required
                            options={[
                                {
                                    label: 'København',
                                    value: 'something 1',
                                },
                                {
                                    label: 'Nordsjælland',
                                    value: 'something 2',
                                },
                                {
                                    label: 'Sjælland',
                                    value: 'something 3',
                                },
                            ]}
                            label={`Something 6`}
                            onChange={() => {}}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <Select
                            isMulti
                            required
                            placeholder={`Vælg lige mere`}
                            options={[
                                {
                                    label: 'København',
                                    value: 'something 1',
                                },
                                {
                                    label: 'Nordsjælland',
                                    value: 'something 2',
                                },
                                {
                                    label: 'Sjælland',
                                    value: 'something 3',
                                },
                            ]}
                            label={`Something 7`}
                            onChange={() => {}}
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default GenericOverviewPage;
