import TrackingManager from '@ida/tracking';

let tracker: TrackingManager;
let isInitialised = false;

export const initTracker = useProdContainer => {
    isInitialised = true;
    tracker = new TrackingManager(useProdContainer);
};

export const track = () => {
    if (!isInitialised) {
        throw new Error('WARNING: GTM tracker is not initialised');
    }

    return tracker;
};
