import * as React from 'react';
import injectSheet, { StyleSheetThemed } from 'react-jss';

import {
    Grid,
    NewslistCluster,
    Page,
    PageHero,
    PageSection,
    Pagination,
    TopCoverImage,
} from '@ingeniorforeningen/design-system';

const styles: StyleSheetThemed = (theme) => ({});

export interface INewsOverviewPageProps {
    Header: string;
    Teaser: string;
    IntroImage: string;
    PagedNews: IPagedNews;
    classes?: any;
    Id: number;
    ShowEditLink: boolean;
}

export interface INewsItem {
    Date: Date;
    Header: string;
    Teaser: string;
    Url: string;
}

export interface IPagedNews {
    Page: number;
    PerPage: number;
    TotalPages: number;
    News: INewsItem[];
}

class NewsOverviewPage extends React.Component<INewsOverviewPageProps> {
    public render() {
        const news = this.props.PagedNews.News.map((newsItem) => {
            return {
                href: newsItem.Url,
                date: newsItem.Date,
                title: newsItem.Header,
                teaser: newsItem.Teaser,
            };
        });

        const pagination = [] as Array<{
            href: string;
            label: string;
            active: boolean;
        }>;
        for (let i = 0; i < this.props.PagedNews.TotalPages; i++) {
            pagination.push({
                href: '?p=' + (i + 1),
                label: (i + 1).toString(),
                active: i + 1 === this.props.PagedNews.Page,
            });
        }

        return (
            <Page condensed hasTopImage={Boolean(this.props.IntroImage)}>
                {this.props.IntroImage && (
                    <TopCoverImage src={this.props.IntroImage} />
                )}
                <PageSection alternate condensed>
                    <Grid container>
                        <Grid item xs={12}>
                            <PageHero
                                align="left"
                                header={this.props.Header}
                                editPage={{
                                    show: this.props.ShowEditLink,
                                    nodeId: this.props.Id,
                                }}
                                paragraph={this.props.Teaser}
                            />
                            <NewslistCluster items={news} embedded />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <Pagination
                                prevLabel="Forrige"
                                nextLabel="Næste"
                                items={pagination}
                                totalPages={this.props.PagedNews.TotalPages}
                            />
                        </Grid>
                    </Grid>
                </PageSection>
            </Page>
        );
    }
}
export default injectSheet(styles)(NewsOverviewPage);
