class Datalayer {
    /**
     * @deprecated The Datalayer should be replaced with calls direct to @ida/tracking
     */
    static trackEvent(event, category, subject) {
        var dataLayerEvent = {
            event: event,
            conversionCategory: category
        };

        if (subject !== '' && subject !== null) {
            dataLayerEvent['conversionSubject'] = subject;
        }

        window['dataLayer'].push(dataLayerEvent);
    }
    /**
     * @deprecated The Datalayer should be replaced with calls direct to @ida/tracking
     */
    static trackInteractionEvent(
        event,
        interactionCategory,
        interactionType,
        interactionSubject
    ) {
        const dataLayerEvent = {
            event: event,
            interactionCategory: interactionCategory
        };

        if (interactionType) {
            dataLayerEvent['interactionType'] = interactionType;
        }

        if (interactionSubject) {
            dataLayerEvent['interactionSubject'] = interactionSubject;
        }

        window['dataLayer'].push(dataLayerEvent);
    }
}

export default Datalayer;
