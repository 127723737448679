import { Icons } from '@ingeniorforeningen/design-system';
import * as React from 'react';
import injectSheet, { StyleSheetThemed } from 'react-jss';

export interface IEditPageButtonProps {
    showEditLink: boolean;
    nodeId: number;
}

const styles: StyleSheetThemed = (theme) => ({});

class EditPageButton extends React.Component<IEditPageButtonProps, any> {
    constructor(props) {
        super(props);
    }

    public render() {
        if (this.props.showEditLink && this.props.nodeId) {
            return (
                <a
                    href={`/umbraco/#/content/content/edit/${this.props.nodeId}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    title="Open in Umbraco"
                >
                    <img
                        src="/images/icon_edit.svg"
                        style={{
                            height: '33px',
                            position: 'relative',
                        }}
                    />
                </a>
            );
        }
        return '';
    }
}

export default injectSheet(styles)(EditPageButton);
