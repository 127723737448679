import { IEcommerceProps } from '@ida/tracking/build/ecommerce';
import { IMeetupPageProps } from '../Pages/MeetupPage';

export const ToEcommerceObject = (
    meetup: IMeetupPageProps,
    category: string,
    listName?: string,
    index?: number
) => {
    const ecommerce = {
        id: meetup.EventNumber,
        affiliation: meetup.GeographicalLocation,
        category: category,
        host: meetup.OrganizerName,
        name: meetup.Title,
        price: meetup.FromPrice,
        activityType: meetup.ActivityTypeEnumValue,
    } as IEcommerceProps;

    if (listName) {
        ecommerce.listName = listName;
    }

    if (index) {
        ecommerce.index = index;
    }
    return ecommerce;
};

export const ToEcommerceList = (
  category: string,
    meetupsProps?: IMeetupPageProps[],
    listName?: string,
): IEcommerceProps[] => {
    const meetups = meetupsProps?.map(
        (meetup: IMeetupPageProps, index: number) => {
            return meetup != null
                ? ToEcommerceObject(meetup, category, listName, index)
                : null;
        }
    ) as any[];

    // networks with empty meetup is an overview page and not a network course, these will be removed
    const filteredMeetups = meetups?.filter(
        (m) => m != null
    ) as IEcommerceProps[];
      
    return filteredMeetups;
};
