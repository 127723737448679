import {
    EmployeeList,
    Grid,
    Page,
    PageHero,
    PageSection,
    TContactCardProps,
} from '@ingeniorforeningen/design-system';
import * as React from 'react';
import injectSheet, { StyleSheetThemed } from 'react-jss';

const styles: StyleSheetThemed = (theme) => ({});

export interface IEmployeesPageProps {
    Header: string;
    Teaser: string;
    Employees: TContactCardProps[];
    classes?: any;
}

class EmployeesPage extends React.Component<IEmployeesPageProps> {
    public render() {
        return (
            <Page>
                <Grid container justify="center">
                    <Grid item md={6} condensed>
                        <PageHero
                            header={this.props.Header}
                            teaser={this.props.Teaser}
                        />
                    </Grid>
                </Grid>
                <PageSection>
                    <Grid container justify="flex-start">
                        <EmployeeList items={this.props.Employees} />
                    </Grid>
                </PageSection>
            </Page>
        );
    }
}

export default injectSheet(styles)(EmployeesPage);
