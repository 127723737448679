import { TLinkBase } from '@ingeniorforeningen/figurine-core';

export const resolveDeliveryLink = (
    link: any,
    path?: string
): TLinkBase & { icon?: any; isSelected?: boolean } => {
    const result: TLinkBase & { icon?: any; isSelected?: boolean } = {
        href: '',
        target: '_self',
        text: '', // This is the default value, but it's not required
    };

    if (!link) {
        return result;
    }

    // UmbracoLink is from a plugin and not the default Umbraco Link Picker
    if (Array.isArray(link)) {
        return resolveDeliveryLink(link?.[0]);
    }

    if (link.itemType) {
        result.href = link.url as string;
    } else {
        // Umbraco Link is from the default Umbraco Link Picker
        result.href =
            link.linkType === 'External' || link.linkType === 'Media'
                ? (link.url as string)
                : (link.route?.path as string);
    }

    result.text = link.title;
    result.target = (link.target || '_self') as TLinkBase['target'];

    if (link.icon) {
        result.icon = link.icon;
    }

    if (path && result?.href && result.href !== '') {
        const slug = result.href.split('/')[result.href.split('/').length - 1];
        if (slug.toLowerCase() === path.toLowerCase()) {
            result.isSelected = true;
        }
    }

    return result;
};
