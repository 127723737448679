import React from 'react';
import {
    PageSection,
    Grid,
    Newsletter as NewsletterComponent,
} from '@ingeniorforeningen/design-system';

export interface INewsletter {
    Header: string;
    Teaser: string;
    CtaLink: string;
    CtaLabel: string;
    Advantages: string[];
}

export const Newsletter: React.FC<INewsletter> = (data) => {
    const { Header, Teaser, CtaLink, CtaLabel, Advantages } = data;

    return (
        <PageSection>
            {Boolean(Header) && (
                <Grid
                    item
                    xs={12}
                    style={{ textAlign: 'center', padding: '0 20px' }}
                >
                    <NewsletterComponent
                        header={Header}
                        teaser={Teaser}
                        advantagesList={Advantages}
                        btnText={CtaLabel}
                        ctaHref={CtaLink}
                    />
                </Grid>
            )}
        </PageSection>
    );
};
