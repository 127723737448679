import React from 'react';
import { Helmet } from 'react-helmet';
import { Grid } from '@ingeniorforeningen/design-system';
import injectSheet, { StyleSheetThemed } from 'react-jss';

type TEmbedSocialGames = {
    classes: any;
    id: string;
};

const styles: StyleSheetThemed = (theme) => ({
    margin: {
        marginTop: '0',
    },
    iframe: {
        border: 'none',
        width: '100%',
        height: '500px',
    },
});

const EmbedSocialGames: React.FC<TEmbedSocialGames> = ({ classes, id }) => {
    return (
        <>
            <Helmet>
                <script src="https://zone-1.cdn.leadfamly.com/campaign/sdk/utilities.responsive_iframe.min.js"></script>
            </Helmet>
            <Grid container className={classes.margin}>
                <Grid item xs={12}>
                    <iframe
                        src={`https://ida.leadfamly.com/${id}/?iframe=dontbreakout`}
                        className={classes.iframe}
                        allow="autoplay; fullscreen"
                    ></iframe>
                </Grid>
            </Grid>
        </>
    );
};
export default injectSheet(styles)(EmbedSocialGames);
