import { Grid } from '@ingeniorforeningen/design-system';
import * as React from 'react';
import injectSheet, { StyleSheetThemed } from 'react-jss';
import { EntranceBox } from '../EntranceBox';

export interface IEntranceCtaProps {
    classes?: any;
}

const styles: StyleSheetThemed = (theme) => ({
    entranceCta: {
        width: '100%',
        marginTop: '20px',
        marginBottom: '40px',
        [theme.breakpoints.up('sm')]: {
            marginTop: 0,
        },
    },
    leftBlock: {
        padding: '0 !important',
        [theme.breakpoints.up('sm')]: {
            paddingRight: '10px !important',
        },
    },
    rightBlock: {
        padding: '0 !important',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '10px !important',
        },
    },
});

class EntranceCta<T extends IEntranceCtaProps> extends React.Component<T, {}> {
    props: any;
    public render() {
        return (
            <div className={this.props.classes.entranceCta}>
                <Grid container narrow>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        className={this.props.classes.leftBlock}
                    >
                        <EntranceBox
                            heading="Bliv en del af vores fællesskab i IDA"
                            buttonText="Bliv medlem"
                            buttonHref="/bliv-medlem"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        className={this.props.classes.rightBlock}
                    >
                        <EntranceBox
                            heading="Kontakt IDA"
                            phoneNumber="33 18 48 48"
                            email="Skriv til os"
                            contactUrl="/kontakt"
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default injectSheet(styles)(EntranceCta);
