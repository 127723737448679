const uncapitalizeObject = (object: any) => {
    let result: typeof object = {};

    const traverse = (oldLevel: any, newLevel: any) => {
        for (var prop in oldLevel) {
            var lowercaseProp = prop.charAt(0).toLowerCase() + prop.slice(1);
            delete newLevel[prop];

            if (typeof oldLevel[prop] !== 'object') {
                newLevel[lowercaseProp] = oldLevel[prop];
            } else {
                newLevel[lowercaseProp] = JSON.parse(
                    JSON.stringify(oldLevel[prop])
                );
                traverse(oldLevel[prop], newLevel[lowercaseProp]);
            }
        }
    };

    traverse(object, result);
    return result;
};

export default uncapitalizeObject;
