import {
    Button,
    Grid,
    Page,
    PageHero,
    PageSection,
} from '@ingeniorforeningen/design-system';
import * as React from 'react';
import injectSheet from 'react-jss';
import dataLayer from '../Analytics/DataLayer';
import UmbracoGrid from '../UmbracoGrid';

const styles = {
    buttonWrapper: {
        boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.5)',
        position: 'fixed',
        bottom: '0',
        right: '0',
        marginBottom: '1em',
        marginRight: '5.5em',
        transform: 'translateY(0.5em)',
        transition: 'all ease 0.2s',
        opacity: '0',
        zIndex: '100',
    },
    show: {
        transform: 'translateY(0em)',
        opacity: '1',
    },
};

export interface IMemberSignupPage {
    Header: string;
    Teaser: string;
    SignupButton: any;
    classes?: any;
    GridContainer: any;
    Id: number;
    ShowEditLink: boolean;
}

type TState = {
    scroll: boolean;
};

class MemberSignupPage extends React.Component<IMemberSignupPage, TState> {
    constructor(props) {
        super(props);
        this.state = {
            scroll: false,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    handleScroll = () => {
        if (document.documentElement.scrollTop > 0) {
            this.setState({ scroll: true });
        } else {
            this.setState({ scroll: false });
        }
    };

    public render() {
        const { classes } = this.props;
        return (
            <Page condensed>
                <PageSection>
                    <Grid container justify="center">
                        <Grid item md={6}>
                            <PageHero
                                header={this.props.Header}
                                editPage={{
                                    show: this.props.ShowEditLink,
                                    nodeId: this.props.Id,
                                }}
                                teaser={this.props.Teaser}
                                cta={{
                                    id: 'signupButton',
                                    onClick: () =>
                                        dataLayer.trackEvent(
                                            'ida.microConversion',
                                            'membership',
                                            null
                                        ),
                                    label: this.props.SignupButton.Label,
                                    href: this.props.SignupButton.Url,
                                }}
                            />
                        </Grid>
                    </Grid>
                </PageSection>
                <UmbracoGrid data={this.props.GridContainer} />
                <Button
                    id="floatingSignupButton"
                    buttonType="primary"
                    component="a"
                    version="cta"
                    className={`${classes.buttonWrapper} ${
                        this.state.scroll ? classes.show : null
                    }`}
                    href="https://indmeld.ida.dk/"
                >
                    {this.props.SignupButton.Label}
                </Button>
            </Page>
        );
    }
}

export default injectSheet(styles)(MemberSignupPage);
