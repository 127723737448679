import {
    BadgeLink,
    Grid,
    Page,
    PageHero,
} from '@ingeniorforeningen/design-system';
import * as React from 'react';
import injectSheet, { StyleSheetThemed } from 'react-jss';

const styles: StyleSheetThemed = (theme) => ({});

export interface IThemeListItem {
    Title: string;
    Link: string;
    Image: string;
    ImageAltText: string;
}
export interface IThemeOverviewPageProps {
    Header: string;
    Teaser: string;
    ThemePagesList: IThemeListItem[];
    classes?: any;
    Id: number;
    ShowEditLink: boolean;
}

class ThemeOverviewPage extends React.Component<IThemeOverviewPageProps> {
    public render() {
        const { Header, Teaser, ThemePagesList } = this.props;

        return (
            <Page condensed>
                <Grid container justify="center">
                    <Grid item xs={12} condensed>
                        <Grid container justify="center">
                            <Grid item md={6}>
                                <PageHero
                                    header={Header}
                                    editPage={{
                                        show: this.props.ShowEditLink,
                                        nodeId: this.props.Id,
                                    }}
                                    teaser={Teaser}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {ThemePagesList.map((theme, key) => {
                        return (
                            <Grid item key={key} xs={12} sm={6} md={3}>
                                <BadgeLink
                                    key={`theme{key}`}
                                    title={theme.Title}
                                    icon={theme.Image}
                                    iconAlt={theme.ImageAltText}
                                    href={theme.Link}
                                    size={260}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
                <br />
                <br />
            </Page>
        );
    }
}

export default injectSheet(styles)(ThemeOverviewPage);
