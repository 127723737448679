import React from 'react';
import { Grid } from '@ingeniorforeningen/design-system';
import injectSheet, { StyleSheetThemed } from 'react-jss';

const styles: StyleSheetThemed = (theme) => ({
    paperWrapper: {},
});

const Form = ({ data }) => {
    return (
        <Grid container>
            <Grid item xs={11} sm={6}>
                <div dangerouslySetInnerHTML={{ __html: data }} />
            </Grid>
        </Grid>
    );
};

export default injectSheet(styles)(Form);
