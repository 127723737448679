import {
    AnchorMenu,
    BadgeLink,
    Grid,
    Heading,
    IconLink,
    Page,
    TopCoverImage,
} from '@ingeniorforeningen/design-system';
import * as React from 'react';
import injectSheet, { StyleSheetThemed } from 'react-jss';
import UmbracoGrid from '../UmbracoGrid';
import EditPageButton from '../Components/EditPageButton';
import { FeatureFlag, IFeatureFlags } from '../Components/FeatureFlag';
import RelatedContent, { IRelatedContent } from '../Components/RelatedContent';
import { Newsletter, INewsletter } from '../Components/Newsletter';

export interface IThemeTag {
    Url: string;
    Title: string;
    ImageUrl: string;
    ImageAltText: string;
}

export interface ITopicTag {
    Url: string;
    Title: string;
    Icon: string;
}

export interface IAnchorMenuItem {
    Label: string;
    Hash: string;
}

export interface IInfoPageProps {
    Date: string;
    ReadingTime: number;
    Theme: IThemeTag;
    Topic: ITopicTag;
    Header: string;
    Teaser: string;
    IntroImage: string;
    GridContainer: any;
    AnchorMenu: IAnchorMenuItem[];
    classes?: any;
    Id: number;
    ShowEditLink: boolean;
    FeatureFlags: IFeatureFlags;
    RelatedContent: IRelatedContent;
    NewsletterSpotBox: INewsletter;
}

const styles: StyleSheetThemed = (theme) => ({
    badgeLink: {
        paddingTop: '20px',
        [theme.breakpoints.up('sm')]: {
            paddingTop: 0,
        },
    },

    badgeLinkOnTopImage: {
        marginTop: '-63px',
        [theme.breakpoints.up('sm')]: {
            marginTop: 0,
        },
    },
});

class InfoPage extends React.Component<IInfoPageProps> {
    public render() {
        let tag;

        const { IntroImage, Theme, Topic, classes } = this.props;
        const newsletterSpotBox = this.props.NewsletterSpotBox;

        // Only render ThemeTag if there is no Topic Tag
        const renderThemeTag =
            Topic == null && Theme != null && Theme.Title != null;
        // Only render Topic Tag if there's actually a Topic
        const renderTopicTag = Topic != null && Topic.Title != null;

        if (renderThemeTag) {
            tag = (
                <BadgeLink
                    href={Theme.Url}
                    icon={Theme.ImageUrl}
                    iconAlt={Theme.ImageAltText}
                    title={Theme.Title}
                    className={
                        Boolean(this.props.IntroImage)
                            ? classes.badgeLinkOnTopImage
                            : classes.badgeLink
                    }
                    withBreadCrumb
                />
            );
        }

        if (renderTopicTag) {
            tag = (
                <IconLink
                    url={Topic.Url}
                    icon={Topic.Icon}
                    label={Topic.Title}
                />
            );
        }

        const anchorMenu = this.props.AnchorMenu
            ? this.props.AnchorMenu.map((item) => {
                  return {
                      href: item.Hash,
                      title: item.Label,
                  };
              })
            : [];

        return (
            <React.Fragment>
                <FeatureFlag show={this.props.FeatureFlags.ShowAnchorMenu}>
                    <AnchorMenu items={anchorMenu} />
                </FeatureFlag>

                <Page
                    hasAnchorMenu={this.hasAnchorMenu()}
                    hasTopImage={Boolean(this.props.IntroImage)}
                >
                    {IntroImage && <TopCoverImage src={IntroImage} />}

                    <Grid container spacing={40} justify="flex-start">
                        <Grid item xs={12} sm={3}>
                            <FeatureFlag
                                show={
                                    this.props.FeatureFlags.ShowInfoBackButton
                                }
                            >
                                {tag}
                            </FeatureFlag>
                        </Grid>
                        <Grid item condensed xs={12} sm={6}>
                            <Heading is="h1" size={600}>
                                {this.props.Header}{' '}
                                <EditPageButton
                                    showEditLink={this.props.ShowEditLink}
                                    nodeId={this.props.Id}
                                />
                            </Heading>
                            <Heading size={200} style={{ fontWeight: 500 }}>
                                {this.props.Teaser}
                            </Heading>
                        </Grid>
                        <Grid item sm={3} />
                    </Grid>

                    <UmbracoGrid
                        condensed
                        data={this.props.GridContainer}
                        {...this.props}
                    />
                    {Boolean(newsletterSpotBox.Header) && (
                        <Newsletter {...newsletterSpotBox} />
                    )}
                    <RelatedContent
                        relatedContent={this.props.RelatedContent}
                        showEditLink={this.props.ShowEditLink}
                    />
                </Page>
            </React.Fragment>
        );
    }

    private hasAnchorMenu = () => {
        return (
            this.props.AnchorMenu &&
            this.props.AnchorMenu.length &&
            this.props.FeatureFlags.ShowAnchorMenu
        );
    };
}

export default injectSheet(styles)(InfoPage);
