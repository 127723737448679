import {
    Grid,
    Page,
    PageHero,
    TopCoverImage,
} from '@ingeniorforeningen/design-system';
import * as React from 'react';
import injectSheet, { StyleSheetThemed } from 'react-jss';
import UmbracoGrid from '../UmbracoGrid';
import { Newsletter, INewsletter } from '../Components/Newsletter';
import dataLayer from '../Analytics/DataLayer';
import RelatedContent, { IRelatedContent } from '../Components/RelatedContent';

const styles: StyleSheetThemed = (theme) => ({});

declare global {
    interface Window {
        dataLayer: any;
    }
}

export interface INewsItem {
    Date: Date;
    Header: string;
    Teaser: string;
    Url: string;
}

export interface IPagedNews {
    Page: number;
    PerPage: number;
    TotalPages: number;
    News: INewsItem[];
}

export interface ISectionFrontpageProps {
    Header: string;
    Teaser: string;
    IntroImage: string;
    PagedNews: IPagedNews;
    GridContainer: any;
    classes?: any;
    Id: number;
    ShowEditLink: boolean;
    NewsletterSpotBox: INewsletter;
    RelatedContent: IRelatedContent;
}

class SectionFrontpage extends React.Component<ISectionFrontpageProps> {
    // temporary solution until new indmeld-ui is live
    componentDidMount() {
        const path = window.location.pathname;
        if (window.dataLayer) {
            if (path === '/bliv-medlem/allerede-medlem') {
                dataLayer.trackEvent('signup.conversion', 'membership signup', {
                    step: 'already member reciept',
                    memberType: 'ordinary',
                    memberInformation: 'already confirmed',
                });
            } else if (path === '/bliv-medlem/anmodning-om-medlemskab') {
                dataLayer.trackEvent('signup.conversion', 'membership signup', {
                    step: 'apply for membership reciept',
                    memberType: 'ordinary',
                    memberInformation: 'confirmed',
                });
            } else if (path === '/bliv-medlem/medlemskab-kvittering') {
                dataLayer.trackEvent('signup.conversion', 'membership signup', {
                    step: 'new member reciept',
                    memberType: 'ordinary',
                    memberInformation: 'confirmed',
                });
            }
        }
    }
    public render() {
        const { GridContainer, Header, IntroImage, Teaser } = this.props;
        const newsletterSpotBox = this.props.NewsletterSpotBox;

        return (
            <Page hasTopImage={Boolean(IntroImage)}>
                {IntroImage && <TopCoverImage src={IntroImage} />}
                <Grid container>
                    <Grid item xs={12}>
                        <PageHero
                            header={Header}
                            editPage={{
                                show: this.props.ShowEditLink,
                                nodeId: this.props.Id,
                            }}
                            teaser={Teaser}
                        />
                    </Grid>
                </Grid>
                <UmbracoGrid data={GridContainer} {...this.props} />
                {Boolean(newsletterSpotBox.Header) && (
                    <Newsletter {...newsletterSpotBox} />
                )}
                <RelatedContent
                    relatedContent={this.props.RelatedContent}
                    showEditLink={this.props.ShowEditLink}
                />
            </Page>
        );
    }
}

export default injectSheet(styles)(SectionFrontpage);
