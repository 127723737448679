import {
    Grid,
    Heading,
    Page,
    TopCoverImage,
} from '@ingeniorforeningen/design-system';
import * as React from 'react';
import injectSheet, { StyleSheetThemed } from 'react-jss';
import UmbracoGrid from '../UmbracoGrid';
import EditPageButton from '../Components/EditPageButton';

export interface IVideoPageProps {
    Header: string;
    Teaser: string;
    IntroImage: string;
    GridContainer: any;
    classes?: any;
    Id: number;
    ShowEditLink: boolean;
}

const styles: StyleSheetThemed = (theme) => ({
    badgeLink: {
        paddingTop: '20px',
        [theme.breakpoints.up('sm')]: {
            paddingTop: 0,
        },
    },

    badgeLinkOnTopImage: {
        marginTop: '-63px',
        [theme.breakpoints.up('sm')]: {
            marginTop: 0,
        },
    },
});

class VideoPage extends React.Component<IVideoPageProps> {
    public render() {
        let tag;

        const { IntroImage, classes } = this.props;

        return (
            <React.Fragment>
                <Page hasTopImage={Boolean(IntroImage)}>
                    {IntroImage && <TopCoverImage src={IntroImage} />}

                    <Grid container spacing={40} justify="flex-start">
                        <Grid item sm={3} />
                        <Grid item condensed xs={12} sm={6}>
                            <Heading is="h1" size={600}>
                                {this.props.Header}{' '}
                                <EditPageButton
                                    showEditLink={this.props.ShowEditLink}
                                    nodeId={this.props.Id}
                                />
                            </Heading>
                            <Heading size={200} style={{ fontWeight: 500 }}>
                                {this.props.Teaser}
                            </Heading>
                        </Grid>
                        <Grid item sm={3} />
                    </Grid>

                    <UmbracoGrid
                        condensed
                        data={this.props.GridContainer}
                        {...this.props}
                    />
                </Page>
            </React.Fragment>
        );
    }
}

export default injectSheet(styles)(VideoPage);
