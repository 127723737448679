import React from 'react';
import { Page, Grid, Heading } from '@ingeniorforeningen/design-system';
import EditPageButton from '../Components/EditPageButton';
import UmbracoGrid from '../UmbracoGrid';
import RelatedContent, { IRelatedContent } from '../Components/RelatedContent';

export interface IPodcastPageProps {
    Header: string;
    Teaser: string;
    GridContainer: any;
    Id: number;
    ShowEditLink: boolean;
    RelatedContent: IRelatedContent;
}

const PodcastPage: React.FC<IPodcastPageProps> = (props) => {
    return (
        <Page>
            <Grid container spacing={40} justify="flex-start">
                <Grid item sm={3} />
                <Grid item condensed xs={12} sm={6}>
                    <Heading is="h1" size={600}>
                        {props.Header}
                        <EditPageButton
                            showEditLink={props.ShowEditLink}
                            nodeId={props.Id}
                        />
                    </Heading>
                    <Heading size={200} style={{ fontWeight: 500 }}>
                        {props.Teaser}
                    </Heading>
                </Grid>
                <Grid item sm={3} />
            </Grid>

            <UmbracoGrid condensed data={props.GridContainer} {...props} />

            <RelatedContent
                relatedContent={props.RelatedContent}
                showEditLink={props.ShowEditLink}
            />
        </Page>
    );
};

export default PodcastPage;
