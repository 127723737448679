import {
    AnchorMenu,
    Dictionary,
    Grid,
    Heading,
    Page,
    PageSection,
    TopCoverImage,
} from '@ingeniorforeningen/design-system';
import * as React from 'react';
import injectSheet, { StyleSheetThemed } from 'react-jss';
import UmbracoGrid from '../UmbracoGrid';
import EditPageButton from '../Components/EditPageButton';
import { FeatureFlag, IFeatureFlags } from '../Components/FeatureFlag';

export interface INetworkOverviewPageProps {
    Header: string;
    Teaser: string;
    IntroImage: string;
    Networks: INetworkPage[];
    GridTop: any;
    GridBottom: any;
    classes?: any;
    AnchorMenu: IAnchorMenuItem[];
    ShowNetworkOverview: boolean;
    Id: number;
    ShowEditLink: boolean;
    FeatureFlags: IFeatureFlags;
}

export interface IAnchorMenuItem {
    Label: string;
    Hash: string;
}

export interface INetworkPage {
    Name: string;
    IntroImage: string;
    IsRegional: boolean;
    Url: string;
}

const styles: StyleSheetThemed = (theme) => ({
    inlinePageContainers: {
        paddingTop: '40px',
        paddingBottom: '40px',

        [theme.breakpoints.up('md')]: {
            paddingTop: '60px',
            paddingBottom: '60px',
        },
    },
});

class NetworkOverviewPage extends React.Component<INetworkOverviewPageProps> {
    public render() {
        const anchorMenu = this.props.AnchorMenu
            ? this.props.AnchorMenu.map((item, index) => {
                  return {
                      href: item.Hash,
                      title: item.Label,
                  };
              })
            : [];
        const { IntroImage } = this.props;
        return (
            <React.Fragment>
                <FeatureFlag show={this.props.FeatureFlags.ShowAnchorMenu}>
                    <AnchorMenu items={anchorMenu} />
                </FeatureFlag>

                <Page
                    condensed
                    hasAnchorMenu={this.hasAnchorMenu()}
                    hasTopImage={Boolean(this.props.IntroImage)}
                >
                    {IntroImage && <TopCoverImage src={IntroImage} />}
                    <Grid
                        container
                        className={this.props.classes.inlinePageContainers}
                    >
                        <Grid
                            item
                            xs={10}
                            sm={8}
                            style={{
                                textAlign: 'center',
                                paddingTop: '0',
                                paddingBottom: '0',
                            }}
                        >
                            <Heading
                                is="h1"
                                size={600}
                                style={{ marginTop: 0 }}
                            >
                                {this.props.Header}{' '}
                                <EditPageButton
                                    showEditLink={this.props.ShowEditLink}
                                    nodeId={this.props.Id}
                                />
                            </Heading>
                            <Heading
                                size={200}
                                style={{ fontWeight: 500, marginBottom: 0 }}
                            >
                                {this.props.Teaser}
                            </Heading>
                        </Grid>
                    </Grid>
                    <UmbracoGrid data={this.props.GridTop} />
                    {this.props.ShowNetworkOverview && (
                        <PageSection alternate>
                            <Grid container>
                                <Grid
                                    item
                                    style={{ paddingTop: 0, paddingBottom: 0 }}
                                >
                                    <Heading
                                        size={600}
                                        style={{ marginTop: 0 }}
                                    >
                                        Oversigt over alle netv&aelig;rk
                                    </Heading>
                                </Grid>
                            </Grid>
                            <Dictionary Dictionary={this.props.Networks} />
                        </PageSection>
                    )}
                    <UmbracoGrid data={this.props.GridBottom} />
                </Page>
            </React.Fragment>
        );
    }

    private hasAnchorMenu = () => {
        return (
            this.props.AnchorMenu &&
            this.props.AnchorMenu.length &&
            this.props.FeatureFlags.ShowAnchorMenu
        );
    };
}

export default injectSheet(styles)(NetworkOverviewPage);
