//!Important
//
//This file has a "sister" file called MeetupStatus.cs in Site/Code/Enums/MeetupStatus.cs
//Any changes here, have to be reflected in the underlying serverside Enum

export enum MeetupStatus {
		IsBeingHeld,
		HasBeenHeld,
		Cancelled,
		WaitingList,
		SoldOut,
		LastEntryDatePassed
}
