import { Link } from '@ingeniorforeningen/design-system';
import * as React from 'react';
import injectSheet, { StyleSheetThemed } from 'react-jss';

const styles: StyleSheetThemed = (theme) => ({
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        lineHeight: '30px',
    },
    error: {
        color: 'hsl(356, 100%, 61%)',
    },
});

export interface IDocumentLoaderProps {
    DocumentsEndpoint: string;
    classes: any;
}

export interface IDocumentLoaderState {
    data: any;
    hasError: boolean;
}

class DocumentLoader extends React.Component<
    IDocumentLoaderProps,
    IDocumentLoaderState
> {
    constructor(props) {
        super(props);
        this.state = { data: [], hasError: false };
    }
    public state: IDocumentLoaderState;

    getData() {
        fetch(this.props.DocumentsEndpoint)
            .then((res) => res.json())
            .then(
                (result) => {
                    this.setState({
                        data: result,
                    });
                },
                (error) => {
                    this.setState({
                        hasError: true,
                    });
                }
            );
    }

    componentDidMount() {
        this.getData();
    }

    private renderHeadline() {
        if (this.state.data.length > 0 || this.state.hasError) {
            return <p style={{ fontWeight: 600 }}>Dokumenter på Mit IDA</p>;
        }
    }

    private renderResults() {
        if (!this.state.hasError) {
            return this.state.data.map((item, index) => {
                return (
                    <div className={this.props.classes.wrapper} key={index}>
                        <p>{item.visibilityName}</p>
                        <Link url={item.url}>Log ind og se filer</Link>
                    </div>
                );
            });
        } else {
            return (
                <div>
                    <p className={this.props.classes.error}>
                        Filhenvisningen fejlede.
                        <br /> Prøv at genindlæse siden.
                    </p>
                </div>
            );
        }
    }

    private mapData() {
        return (
            <div>
                {this.renderHeadline()}
                {this.renderResults()}
            </div>
        );
    }

    public render() {
        return this.mapData();
    }
}

export default injectSheet(styles)(DocumentLoader);
