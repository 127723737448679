import {
    Grid,
    Page,
    PageHero,
    TopicTeaserBox,
} from '@ingeniorforeningen/design-system';
import * as React from 'react';
import injectSheet, { StyleSheetThemed } from 'react-jss';
import { IMeetupPageProps } from './MeetupPage';
import RelatedContent, { IRelatedContent } from '../Components/RelatedContent';
import UmbracoGrid from '../UmbracoGrid';

export interface ITopicItem {
    Title: string;
    Description: string;
    Url?: string;
}

export interface IAppProps {
    Header: string;
    Teaser: string;
    TopicList: ITopicItem[];
    classes?: any;
    Id: number;
    ShowEditLink: boolean;
    Meetup: IMeetupPageProps;
    RelatedContent: IRelatedContent;
    GridContainer: any;
}

const styles: StyleSheetThemed = (theme) => ({
    gridContainer: {
        [theme.breakpoints.up('sm')]: {
            display: 'grid',
            gridTemplateColumns: '50% 50%',
        },
        '@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)':
            {
                display: 'flex',
                maxWidth: '1160px',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
            },
    },
    gridItem: {
        maxWidth: 'none',
        // IE quirk
        '@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)':
            {
                flexBasis: '100%',
            },
        '@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) and (min-width: 600px)':
            {
                flexBasis: '44%',
            },
        '@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) and (min-width: 960px)':
            {
                flexBasis: '45%',
            },
        '@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) and (min-width: 1280px)':
            {
                flexBasis: '46%',
            },
    },
});

class TopicPage extends React.Component<IAppProps> {
    public onFullWidthContentVisibilityChange = (isVisible) => {
        if (this.props.Meetup) {
            this.setState({ hideForFullWidthContent: isVisible });
        }
    };

    public render() {
        const { Header, Teaser, TopicList } = this.props;

        return (
            <Page>
                <Grid item xs={12}>
                    <Grid container justify="center">
                        <Grid
                            item
                            md={6}
                            style={{ padding: 0, flexBasis: '100%' }}
                        >
                            <PageHero
                                header={Header}
                                editPage={{
                                    show: this.props.ShowEditLink,
                                    nodeId: this.props.Id,
                                }}
                                teaser={Teaser}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container className={this.props.classes.gridContainer}>
                    {TopicList.map((topic, index) => (
                        <Grid
                            item
                            key={index}
                            xs={12}
                            sm={6}
                            className={this.props.classes.gridItem}
                        >
                            <TopicTeaserBox
                                key={`topic${index}`}
                                title={topic.Title}
                                teaser={topic.Description}
                                href={topic.Url}
                            />
                        </Grid>
                    ))}
                </Grid>

                <UmbracoGrid
                    condensed
                    data={this.props.GridContainer}
                    {...this.props}
                />

                <RelatedContent
                    relatedContent={this.props.RelatedContent}
                    showEditLink={this.props.ShowEditLink}
                    onFullWidthContentVisibilityChange={
                        this.onFullWidthContentVisibilityChange
                    }
                />
            </Page>
        );
    }
}

export default injectSheet(styles)(TopicPage);
