import {
    CourseList,
    Grid,
    Icons,
    Link,
    Page,
    PageHero,
} from '@ingeniorforeningen/design-system';
import * as React from 'react';
import injectSheet, { StyleSheetThemed } from 'react-jss';
import UmbracoGrid from '../UmbracoGrid';
import { IMeetupPageProps } from './MeetupPage';
import { FeatureFlag, IFeatureFlags } from '../Components/FeatureFlag';
import RelatedContent, { IRelatedContent } from '../Components/RelatedContent';
import { Newsletter, INewsletter } from '../Components/Newsletter';

const styles: StyleSheetThemed = (theme) => ({
    link: {
        textDecoration: 'none',
        color: theme.colors.neutralBlack,
    },
    textTitle: {
        marginTop: 0,
    },
});

export interface ICourseListItem {
    Title: string;
    Link: string;
    Image: string;
}

export interface IMeetupFrontpageLink {
    Title: string;
    Link: string;
}

export interface ICourseOverviewPageProps {
    Header: string;
    Teaser: string;
    CourseListItems: ICourseListItem[];
    GridContainer: any;
    MeetupFrontpageLink: IMeetupFrontpageLink;
    Meetup: IMeetupPageProps;
    NewsletterSpotBox: INewsletter;
    classes?: any;
    Id: number;
    ShowEditLink: boolean;
    FeatureFlags: IFeatureFlags;
    RelatedContent: IRelatedContent;
}

class CourseOverviewPage extends React.Component<ICourseOverviewPageProps> {
    public onFullWidthContentVisibilityChange = (isVisible) => {
        if (this.props.Meetup) {
            this.setState({ hideForFullWidthContent: isVisible });
        }
    };

    public render() {
        const newsletterSpotBox = this.props.NewsletterSpotBox;
        const items = this.props.CourseListItems
            ? this.props.CourseListItems.map((item) => {
                  return {
                      imageUrl: item.Image,
                      title: item.Title,
                      href: item.Link,
                  };
              })
            : [];

        return (
            <Page>
                <PageHero
                    header={this.props.Header}
                    editPage={{
                        show: this.props.ShowEditLink,
                        nodeId: this.props.Id,
                    }}
                    teaser={this.props.Teaser}
                />

                <FeatureFlag
                    show={this.props.FeatureFlags.ShowCoursesBackButton}
                >
                    <Grid container>
                        <Grid item xs={12} slim>
                            <Link url={this.props.MeetupFrontpageLink.Link}>
                                <Icons.ArrowDown
                                    style={{
                                        transform: 'rotate(90deg)',
                                        marginBottom: '-9px',
                                        marginLeft: '-5px',
                                        marginRight: '5px',
                                    }}
                                />
                                {this.props.MeetupFrontpageLink.Title}
                            </Link>
                        </Grid>
                    </Grid>
                </FeatureFlag>
                <Grid container>
                    <Grid item xs={12} slim>
                        <CourseList categoryTile={true} items={items} />
                    </Grid>
                </Grid>
                <UmbracoGrid data={this.props.GridContainer} />
                {Boolean(newsletterSpotBox.Header) && (
                    <Newsletter {...newsletterSpotBox} />
                )}
                <RelatedContent
                    relatedContent={this.props.RelatedContent}
                    showEditLink={this.props.ShowEditLink}
                    onFullWidthContentVisibilityChange={
                        this.onFullWidthContentVisibilityChange
                    }
                />
            </Page>
        );
    }
}

export default injectSheet(styles)(CourseOverviewPage);
