import { Grid } from '@ingeniorforeningen/design-system';
import * as React from 'react';
import injectSheet, { StyleSheetThemed } from 'react-jss';

const styles: StyleSheetThemed = (theme) => ({
    '@global': {
        '.spinner, .spinner:before, .spinner:after': {
            borderRadius: '50%',
            width: '2.5em',
            height: '2.5em',
            animation: 'load7 1.8s infinite ease-in-out',
        },
        '.spinner': {
            color: '#000000',
            fontSize: '10px',
            margin: '80px auto',
            position: 'relative',
            textIndent: '-9999em',
            transform: 'translateZ(0)',
            animationDelay: '-0.16s',
        },
        '.spinner:before, .spinner:after': {
            content: '""',
            position: 'absolute',
            top: 0,
        },
        '.spinner:before': {
            left: '-3.5em',
            animationDelay: '-0.32s',
        },
        '.spinner:after': {
            left: '3.5em',
        },
        '#personSearch label': {
            margin: '0px 10px',
        },
        '#personSearch h2': {
            marginBottom: '30px',
        },
        '#personSearch form': {
            marginBottom: '30px',
        },
        '.btn-primary': {
            transition: 'transform 0.2s',
            backgroundColor: [[theme.colors.brandPrimary], '!important'],
            border: [['none'], '!important'],
            color: [[theme.colors.neutralWhite], '!important'],
            '&:hover': {
                transform: 'scale(1.05)',
            },
        },
        'a:hover': {
            textDecoration: 'none', //because stackpath script overwrites DS styles
            color: 'white', //because stackpath script overwrites DS styles
        },
    },
    '@keyframes load7': {
        '0%, 80%, 100%': { boxShadow: '0 2.5em 0 -1.3em' },
        '40%': { boxShadow: '0 2.5em 0 0' },
    },
});

export interface IActiveSearchProps {
    activeSearchUrl: string;
    classes?: any;
}

class ActiveSearch extends React.Component<IActiveSearchProps> {
    private static defaultProps: Partial<IActiveSearchProps> = {};

    public componentDidMount() {
        const urls = [
            'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.1/jquery.js',
            'https://cdnjs.cloudflare.com/ajax/libs/prop-types/15.6.2/prop-types.js',
            'https://unpkg.com/create-react-class@15.6.2/create-react-class.min.js',
            this.props.activeSearchUrl,
        ];

        // Add extra scripts
        for (let i = 0; i < urls.length; i++) {
            const j = document.createElement('script');
            j.src = urls[i];
            j.async = false;
            document.body.appendChild(j);
        }

        // Add Bootstrap
        const stylesheetLink = document.createElement('link');
        stylesheetLink.rel = 'stylesheet';
        stylesheetLink.href =
            'https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css';
        stylesheetLink.integrity =
            'sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO';
        stylesheetLink.crossOrigin = 'anonymous';
        const firstStylesheet = document.head.querySelector('style');
        document.head.insertBefore(stylesheetLink, firstStylesheet);
    }

    public render() {
        return (
            <Grid justify="center">
                <Grid item xs={12}>
                    <div
                        id="personSearchWrapper"
                        data-env="udv"
                        data-header=""
                    ></div>
                </Grid>
            </Grid>
        );
    }
}

export default injectSheet(styles)(ActiveSearch);
