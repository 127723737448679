import * as React from 'react';

export interface ICludoSearchProps {
    a?: string;
}

const CludoSearchPage: React.FC<ICludoSearchProps> = () => {
    return (
        <>
            <div
                style={{ maxWidth: '1160px', margin: '0 auto' }}
                dangerouslySetInnerHTML={{
                    __html: `
                                    <div id="cludo-search-results" class="container_12">
                                    <!-- Banner, need to place banner outsiide razor template:
                                                <div class="search-banner"></div>
                                                -->
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h1 style="text-align:center;">Hvad søger du?</h1>
                                            <div id="cludo-search-content-form" role="search">
                                                <label for="cludo-search-content-form-input">Indsæt søgeord for at søge
                                                    på
                                                    hjemmesiden</label>
                                                <input name="searchrequest" id="cludo-search-content-form-input" type="search" placeholder="Søg på emne, titel eller arr.nr." value="" title="Indsæt søgeord" autocomplete="off" aria-autocomplete="list" aria-haspopup="true" class="c01166">
                                                <button type="submit" title="Søg"></button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row cludo-mb-3">
                                        <div class="col-md-12">
                                            <div class="search-filters" aria-controls="search-results"></div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="search-results-container">
                                                <div class="search-did-you-mean" role="Complementary"></div>
                                                <div class="search-results" role="region" id="search-results" aria-live="polite"></div>
                                                <div id="cludo-pre-search" class="row category-select">
                                                    <h3 class="category-select__label">Kategorier</h3>
                                                    <ul class="category-select__list">
                                                            <li class="category-select__list-item">
                                                                <button class="category-select__list-item-button" onclick="CludoSearch.params.query='*'; addFiltersForFuture(); CludoSearch.facet('Category', 'Arrangementer')">Arrangementer<svg class="sc-bdVaJa ifjVAL" focusable="false" viewbox="0 0 800 800" aria-hidden="true" role="img"><path d="M786.22 601.92L394.38 214.81 13.79 590.82 0 576.87l394.38-389.64L800 587.97l-13.78 13.95z"></path></svg></button>
                                                            </li>
                                                            <li class="category-select__list-item">
                                                                <button class="category-select__list-item-button" onclick="CludoSearch.params.query='*'; addFiltersForFuture(); CludoSearch.facet('Category', 'Kurser')">Kurser<svg class="sc-bdVaJa ifjVAL" focusable="false" viewbox="0 0 800 800" aria-hidden="true" role="img"><path d="M786.22 601.92L394.38 214.81 13.79 590.82 0 576.87l394.38-389.64L800 587.97l-13.78 13.95z"></path></svg></button>
                                                            </li>
                                                            <li class="category-select__list-item">
                                                                <button class="category-select__list-item-button" onclick="CludoSearch.params.query='*';CludoSearch.facet('Category', 'Netværk')">Netværk<svg class="sc-bdVaJa ifjVAL" focusable="false" viewbox="0 0 800 800" aria-hidden="true" role="img"><path d="M786.22 601.92L394.38 214.81 13.79 590.82 0 576.87l394.38-389.64L800 587.97l-13.78 13.95z"></path></svg></button>
                                                            </li>
                                                            <li class="category-select__list-item">
                                                                <button class="category-select__list-item-button" onclick="CludoSearch.params.query='*';CludoSearch.facet('Category', 'Nyt fra IDA')">Nyt fra IDA<svg class="sc-bdVaJa ifjVAL" focusable="false" viewbox="0 0 800 800" aria-hidden="true" role="img"><path d="M786.22 601.92L394.38 214.81 13.79 590.82 0 576.87l394.38-389.64L800 587.97l-13.78 13.95z"></path></svg></button>
                                                            </li>
                                                            <li class="category-select__list-item">
                                                                <button class="category-select__list-item-button" onclick="CludoSearch.params.query='*';CludoSearch.facet('Category', 'Podcast')">Podcast<svg class="sc-bdVaJa ifjVAL" focusable="false" viewbox="0 0 800 800" aria-hidden="true" role="img"><path d="M786.22 601.92L394.38 214.81 13.79 590.82 0 576.87l394.38-389.64L800 587.97l-13.78 13.95z"></path></svg></button>
                                                            </li>
                                                            <li class="category-select__list-item">
                                                                <button class="category-select__list-item-button" onclick="CludoSearch.params.query='*';CludoSearch.facet('Category', 'Råd & karriere')">Råd & karriere<svg class="sc-bdVaJa ifjVAL" focusable="false" viewbox="0 0 800 800" aria-hidden="true" role="img"><path d="M786.22 601.92L394.38 214.81 13.79 590.82 0 576.87l394.38-389.64L800 587.97l-13.78 13.95z"></path></svg></button>
                                                            </li>
                                                            <li class="category-select__list-item">
                                                                <button class="category-select__list-item-button" onclick="CludoSearch.params.query='*';CludoSearch.facet('Category', 'Afholdte webinarer')">Afholdte webinarer<svg class="sc-bdVaJa ifjVAL" focusable="false" viewbox="0 0 800 800" aria-hidden="true" role="img"><path d="M786.22 601.92L394.38 214.81 13.79 590.82 0 576.87l394.38-389.64L800 587.97l-13.78 13.95z"></path></svg></button>
                                                            </li>
                                                            <li class="category-select__list-item">
                                                                <button class="category-select__list-item-button" onclick="CludoSearch.params.query='*';CludoSearch.facet('Category', 'Øvrige')">Øvrige<svg class="sc-bdVaJa ifjVAL" focusable="false" viewbox="0 0 800 800" aria-hidden="true" role="img"><path d="M786.22 601.92L394.38 214.81 13.79 590.82 0 576.87l394.38-389.64L800 587.97l-13.78 13.95z"></path></svg></button>
                                                            </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                `,
                }}
            />
        </>
    );
};

export default CludoSearchPage;
