import { Radio } from '@ingeniorforeningen/design-system';
import { MeetupStatus } from '../Enums/MeetupStatus';

import classNames from 'classnames';
import * as React from 'react';
import injectSheet, { StyleSheetThemed } from 'react-jss';
import SignupButton from '../Components/SignupButton';
import { IMeetupPageProps } from '../Pages/MeetupPage';

const styles: StyleSheetThemed = (theme) => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            height: '100%',
        },
    },
    radio: {
        marginBottom: 0,
    },
    dateWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 16px',
    },
    statusLabel: {
        marginLeft: '32px',
        fontWeight: 600,
    },
    dateWrapperSelected: {
        background: theme.colors.neutralGrey10,
    },
    section: {
        padding: '20px',
    },
    sectionDatesHeader: {
        marginTop: 0,
        marginBottom: '8px',
    },
    sectionDates: {
        borderBottom: `1px solid ${theme.colors.brandSecondary}`,
        whiteSpace: 'nowrap',
        [theme.breakpoints.up('sm')]: {
            borderBottom: 0,
        },
    },
    sectionDetails: {
        borderLeft: 0,
        width: '100%',

        [theme.breakpoints.up('sm')]: {
            borderLeft: `1px solid ${theme.colors.brandSecondary}`,
        },
    },
    locationDetails: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '32px',
    },
    moduleWrapper: {
        display: 'flex',
        marginBottom: '15px',
    },
    moduleWrapperDay: {
        minWidth: '80px',
        flex: 1,
    },
    moduleWrapperTime: {
        flex: 6,
    },
    sectionHeading: {
        marginTop: 0,
        marginBottom: '16px',
        fontWeight: 600,
    },
    addressLine: {
        margin: 0,
    },
    map: {
        position: 'absolute',
        left: '-100vw',

        [theme.breakpoints.up('sm')]: {
            left: 'auto',
            position: 'static',
            width: '300px',
            height: '250px',
            overflow: 'hidden',
            marginRight: '48px',
            border: 0,
            margin: '0 0 40px 0',
        },
    },
    buttonSection: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '20px',

        [theme.breakpoints.down('sm')]: {
            '& div': {
                minWidth: 'calc(100vw - 50px)',

                '& a': {
                    display: 'block',
                    minWidth: '100%',
                },

                '& button': {
                    minWidth: '100%',
                },
            },
        },
    },
});

export interface IOccurrence {
    type: string;
    title: string;
    startDate: string;
    startDateId: string;
    modules: IModule[];
    address: IAddress;
    meetupNumber: number;
    accommodation?: boolean;
    status?: string;
    statusCode: MeetupStatus;
    deadline: string;
    signupUrl: string;
    meetup: IMeetupPageProps;
}

export interface IModule {
    title: string;
    timeSpan: string;
    dateSpan: string;
}

export interface IAddress {
    locationName: string;
    addressLines: string;
    zipCode: string;
    city: string;
    lat: number;
    lng: number;
}

export interface ILocation {
    locationName: string;
    occurrences: IOccurrence[];
}

export interface ICourseLocationSignupProps {
    meetup?: IMeetupPageProps;
    location: ILocation;
    index: number;
    googleApiKey: string;
    onLocationChange: any;
    selectedOccurrenceIndex: number;
    classes?: any;
}

class CourseLocationSignup extends React.Component<ICourseLocationSignupProps> {
    constructor(props) {
        super(props);
    }

    public deadline(occurrences, selectedOccurrenceIndex) {
        if (
            occurrences[selectedOccurrenceIndex].availableSeats >= 1 &&
            occurrences[selectedOccurrenceIndex].availableSeats <= 3
        ) {
            return <p style={{ marginBottom: 0 }}>Få ledige pladser</p>;
        }
        return (
            <p style={{ marginBottom: 0 }}>
                Tilmeldingsfrist {occurrences[selectedOccurrenceIndex].deadline}
            </p>
        );
    }

    public render() {
        const {
            props: {
                location: { occurrences },
                index,
                googleApiKey,
                classes,
                selectedOccurrenceIndex,
            },
        } = this;

        return (
            <div className={classes.root}>
                <div className={`${classes.section} ${classes.sectionDates}`}>
                    <p className={classes.sectionHeading}>Startdatoer</p>
                    {occurrences.map((occurrence, occindex) => {
                        const checked = selectedOccurrenceIndex === occindex;
                        const statusLabel = () => {
                            if (
                                occurrence.statusCode !==
                                    MeetupStatus.IsBeingHeld &&
                                occurrence.statusCode !==
                                    MeetupStatus.LastEntryDatePassed
                            ) {
                                return (
                                    <div
                                        key={`status${occindex}`}
                                        className={classes.statusLabel}
                                    >
                                        {occurrence.status}
                                    </div>
                                );
                            }
                        };
                        const dateWrapperClassName = classNames(
                            classes.dateWrapper,
                            {
                                [classes.dateWrapperSelected]: checked,
                            }
                        );

                        return (
                            <div
                                tabIndex={occindex}
                                key={`startdateWrapper${occindex}`}
                                className={dateWrapperClassName}
                            >
                                <Radio
                                    key={`startdate${occindex}`}
                                    className={classes.radio}
                                    name={`startDate${index}`}
                                    value={occindex}
                                    onChange={() =>
                                        this.props.onLocationChange(
                                            index,
                                            occindex
                                        )
                                    }
                                    label={occurrence.startDate}
                                    checked={checked}
                                />{' '}
                                {statusLabel()}
                            </div>
                        );
                    })}
                </div>
                <div className={`${classes.section} ${classes.sectionDetails}`}>
                    <p className={classes.sectionHeading}>Kursusmoduler</p>

                    {occurrences[selectedOccurrenceIndex].modules.map(
                        (module, modindex) => {
                            return (
                                <div
                                    key={`modules${modindex}`}
                                    className={classes.moduleWrapper}
                                >
                                    <div className={classes.moduleWrapperDay}>
                                        {module.title}
                                    </div>
                                    <div className={classes.moduleWrapperTime}>
                                        <div>{module.dateSpan}</div>
                                        <div>{module.timeSpan}</div>
                                    </div>
                                </div>
                            );
                        }
                    )}
                    {occurrences[selectedOccurrenceIndex].address
                        .locationName &&
                        !occurrences[
                            selectedOccurrenceIndex
                        ].address.locationName.includes('Online') && (
                            <div className={classes.locationDetails}>
                                <div className={classes.address}>
                                    <p className={classes.sectionHeading}>
                                        Adresse
                                    </p>

                                    <p className={classes.addressLine}>
                                        {
                                            occurrences[selectedOccurrenceIndex]
                                                .address.locationName
                                        }
                                    </p>
                                    {occurrences[selectedOccurrenceIndex]
                                        .address.addressLines && (
                                        <div>
                                            <p className={classes.addressLine}>
                                                {
                                                    occurrences[
                                                        selectedOccurrenceIndex
                                                    ].address.addressLines
                                                }
                                            </p>
                                            <p className={classes.addressLine}>
                                                {
                                                    occurrences[
                                                        selectedOccurrenceIndex
                                                    ].address.zipCode
                                                }{' '}
                                                {
                                                    occurrences[
                                                        selectedOccurrenceIndex
                                                    ].address.city
                                                }
                                            </p>
                                        </div>
                                    )}

                                    <br />
                                    <p className={classes.sectionHeading}>
                                        Arrangementsnummer
                                    </p>

                                    <p>
                                        {
                                            occurrences[selectedOccurrenceIndex]
                                                .meetupNumber
                                        }
                                    </p>
                                </div>
                                {occurrences[selectedOccurrenceIndex].address
                                    .addressLines && (
                                    <iframe
                                        className={classes.map}
                                        frameBorder="0"
                                        src={
                                            'https://www.google.com/maps/embed/v1/place?key=' +
                                            googleApiKey +
                                            '&q=' +
                                            occurrences[selectedOccurrenceIndex]
                                                .address.addressLines +
                                            ' ' +
                                            occurrences[selectedOccurrenceIndex]
                                                .address.zipCode +
                                            ' ' +
                                            occurrences[selectedOccurrenceIndex]
                                                .address.city
                                        }
                                    />
                                )}
                            </div>
                        )}

                    {occurrences[selectedOccurrenceIndex].type ===
                        'Webinar' && (
                        <div className={classes.locationDetails}>
                            <div className={classes.address}>
                                <p className={classes.sectionHeading}>
                                    Arrangementsnummer
                                </p>
                                <p>
                                    {
                                        occurrences[selectedOccurrenceIndex]
                                            .meetupNumber
                                    }
                                </p>
                            </div>
                        </div>
                    )}

                    <div className={classes.buttonSection}>
                        <SignupButton
                            statusCode={
                                occurrences[selectedOccurrenceIndex].statusCode
                            }
                            signupUrl={
                                occurrences[selectedOccurrenceIndex].signupUrl
                            }
                            meetupTitle={
                                occurrences[selectedOccurrenceIndex].title
                            }
                            meetupType={
                                occurrences[selectedOccurrenceIndex].type
                            }
                            helpText={this.deadline(
                                occurrences,
                                selectedOccurrenceIndex
                            )}
                            meetup={occurrences[selectedOccurrenceIndex].meetup}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default injectSheet(styles)(CourseLocationSignup);
