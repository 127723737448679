module.exports = {
    App: require('./App'),
    ArticlePage: require('./Pages/ArticlePage'),
    Frontpage: require('./Pages/Frontpage'),
    GenericOverviewPage: require('./Pages/GenericOverviewPage'),
    InfoPage: require('./Pages/InfoPage'),
    VideoPage: require('./Pages/VideoPage'),
    PodcastPage: require('./Pages/PodcastPage'),
    MeetupPage: require('./Pages/MeetupPage'),
    MeetupFrontpage: require('./Pages/MeetupFrontpage'),
    NetworkOverviewPage: require('./Pages/NetworkOverviewPage'),
    NetworkPage: require('./Pages/NetworkPage'),
    SectionFrontpage: require('./Pages/SectionFrontpage'),
    ThemeOverviewPage: require('./Pages/ThemeOverviewPage'),
    ThemePage: require('./Pages/ThemePage'),
    TopicPage: require('./Pages/TopicPage'),
    CoursePage: require('./Pages/CoursePage'),
    DocumentLoader: require('./Async/DocumentLoader'),
    MemberSignupPage: require('./Pages/MemberSignupPage'),
    SignupButton: require('./Components/SignupButton'),
};
