import {
    BadgeHeader,
    ContactBand,
    Grid,
    InspirationCluster,
    Page,
    PageSection,
    Paragraph,
    SectionHeader,
    TopCoverImage,
} from '@ingeniorforeningen/design-system';
import * as React from 'react';
import injectSheet, { StyleSheetThemed } from 'react-jss';
import UmbracoGrid from '../UmbracoGrid';
import EditPageButton from '../Components/EditPageButton';

export interface IContact {
    Image: string;
    Name: string;
    Occupation: string;
    Phone: string;
    Email: string;
    IsValid: boolean;
}

export interface IThemePageProps {
    Name: string;
    Header: string;
    Teaser: string;
    IntroImage: string;
    IntroImageThumbnail: string;
    IntroImageAltText: string;
    Contact: IContact;
    TopGridContainer: any;
    GridContainer: any;
    classes?: any;
    InspirationClusterData: string;
    Id: number;
    ShowEditLink: boolean;
}

const styles: StyleSheetThemed = (theme) => ({});

class ThemePage extends React.Component<IThemePageProps> {
    public render() {
        const {
            GridContainer,
            TopGridContainer,
            Header,
            IntroImage,
            IntroImageAltText,
            IntroImageThumbnail,
            Teaser,
            InspirationClusterData,
            Contact,
            classes,
        } = this.props;

        return (
            <Page hasTopImage={Boolean(IntroImage)}>
                <TopCoverImage src={IntroImage} />
                <Grid container spacing={40} justify="center">
                    <Grid item md={6} justify="center">
                        <BadgeHeader
                            tag="Tema"
                            title={Header}
                            icon={IntroImageThumbnail}
                            iconAlt={IntroImageAltText}
                        />{' '}
                        <EditPageButton
                            showEditLink={this.props.ShowEditLink}
                            nodeId={this.props.Id}
                        />
                    </Grid>
                </Grid>
                <Grid container justify="center">
                    <Grid item md={6}>
                        <Paragraph half>{Teaser}</Paragraph>
                    </Grid>
                </Grid>
                <UmbracoGrid data={TopGridContainer} />
                <InspirationCluster rows={JSON.parse(InspirationClusterData)} />
                <UmbracoGrid data={GridContainer} />
                {Boolean(Contact.IsValid) && (
                    <PageSection alternate>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <SectionHeader
                                    hash="vil-du-vide-mere"
                                    invisible
                                >
                                    Vil du vide mere
                                </SectionHeader>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                            <ContactBand {...Contact} />
                        </Grid>
                    </PageSection>
                )}
            </Page>
        );
    }
}

export default injectSheet(styles)(ThemePage);
