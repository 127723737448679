import {
    HeroFeature,
    Page,
    PageSection,
} from '@ingeniorforeningen/design-system';
import * as React from 'react';
import injectSheet, { StyleSheetThemed } from 'react-jss';
import { EntranceCta } from '../../Components/Components/EntranceCta';
import UmbracoGrid from '../UmbracoGrid';

const styles: StyleSheetThemed = (theme) => ({});

export interface IFrontPageProps {
    classes?: any;
    GridContainer: any;
    HeroSlides: any;
    ShowEntranceCta: boolean;
}

class Frontpage extends React.Component<IFrontPageProps> {
    public render() {
        let heroFeature, entranceCta;
        if (this.props.HeroSlides.length > 0) {
            heroFeature = <HeroFeature slides={this.props.HeroSlides} />;
        }
        if (this.props.ShowEntranceCta) {
            entranceCta = <EntranceCta />;
        }

        return (
            <Page hasTopImage>
                {heroFeature}
                <PageSection>
                    {entranceCta}
                    <UmbracoGrid
                        data={this.props.GridContainer}
                        {...this.props}
                    />
                </PageSection>
            </Page>
        );
    }
}

export default injectSheet(styles)(Frontpage);
