import * as React from 'react';
import injectSheet, { StyleSheetThemed } from 'react-jss';
import UmbracoGrid from '../UmbracoGrid';
import { IFeatureFlags } from "../Components/FeatureFlag";
import { Newsletter, INewsletter } from '../Components/Newsletter';

import MeetupPage from './MeetupPage';

export interface IAnchorMenuItem {
    Label: string;
    Hash: string;
}

export interface IConferencePageProps {
    Meetup: any;
    IntroImage?: string;
    GridContainer: any;
    classes?: any;
    AnchorMenu: IAnchorMenuItem[];
    Id: number;
    FeatureFlags: IFeatureFlags;
    NewsletterSpotBox: INewsletter;
}

const styles: StyleSheetThemed = theme => ({
    umbracoGridWrapper: {
        paddingLeft: '20px',
        paddingRight: '20px',

        [theme.breakpoints.up('sm')]: {
            paddingLeft: 0,
            paddingRight: 0
        },

        '& $pageContainer': {
            padding: '0'
        }
    }
});

class ConferencePage extends React.Component<IConferencePageProps> {
    public render() {
        const newsletterSpotBox = this.props.NewsletterSpotBox;
        const meetupData = this.props.Meetup;
        meetupData.AnchorMenu = this.props.AnchorMenu;
        meetupData.IntroImage = this.props.IntroImage;
        meetupData.FeatureFlags = this.props.FeatureFlags;

        return (
            <React.Fragment>
                <MeetupPage {...meetupData} />
                <UmbracoGrid data={this.props.GridContainer} />
                {Boolean(newsletterSpotBox.Header) && (
                    <Newsletter {...newsletterSpotBox} />
                )}
            </React.Fragment>
        );
    }
}

export default injectSheet(styles)(ConferencePage);
