import React from 'react';
import injectSheet, { StyleSheetThemed } from 'react-jss';
import { MeetupStatus } from '../../Enums/MeetupStatus';

const styles: StyleSheetThemed = theme => ({
    listWrapper: {
        padding: '0',
        '& span': {
            width: '10px',
            height: '10px',
            display: 'inline-block',
            borderRadius: '100px'
        },
        '& p': {
            margin: '0'
        },
        '& li': {
            listStyle: 'none',
            lineHeight: '24px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row'
        },
        '& div': {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between'
        }
    },
    itemText: {
        flex: '1',
        margin: '0',
        display: 'inline-block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        paddingLeft: '10px',
        textOverflow: 'ellipsis',
        width: 'max-content'
    },
    maxWidth: {
        maxWidth: '70px'
    },
    cancelled: {
        backgroundColor: 'red'
    },
    warning: {
        backgroundColor: 'hsl(50, 100%, 50%)'
    },
    available: {
        backgroundColor: '#4fe04f'
    }
});

const CourseItems = ({ items, classes }) => {
    const getIndicatorColor = item => {
        if (
            item.statusCode === MeetupStatus.SoldOut ||
            item.statusCode === MeetupStatus.Cancelled ||
            item.statusCode === MeetupStatus.LastEntryDatePassed
        ) {
            return 'cancelled';
        } else if (item.availableSeats < 3) {
            return 'warning';
        }

        return 'available';
    };

    const getLocation = (city: string, geo: string) => {
        if (city != null && geo != 'Online arrangement') {
            return city;
        }
        return 'Online';
    };

    return (
        <ul className={classes.listWrapper}>
            {items.map((item, index) => {
                if (index <= 2) {
                    return (
                        <li key={index}>
                            <span
                                className={classes[getIndicatorColor(item)]}
                            />
                            <div>
                                <p
                                    className={`${classes.itemText} ${classes.maxWidth}`}
                                >
                                    {item.startDate}
                                </p>
                                <p className={classes.itemText}>
                                    {getLocation(
                                        item.city,
                                        item.geographicalLocation
                                    )}
                                </p>
                            </div>
                        </li>
                    );
                }
            })}
        </ul>
    );
};

export default injectSheet(styles)(CourseItems);
