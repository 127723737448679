import {
    AnchorMenu,
    Grid,
    Heading,
    Link,
    Page,
    PageSection,
    Paragraph,
    Richtext,
    SectionHeader,
    TopCoverImage,
    MultiIconList,
} from '@ingeniorforeningen/design-system';
import classNames from 'classnames';
import * as React from 'react';
import injectSheet, { StyleSheetThemed } from 'react-jss';
import DocumentLoader from '../Async/DocumentLoader';
import SignupButton from '../Components/SignupButton';
import { MeetupStatus } from '../Enums/MeetupStatus';
import EditPageButton from '../Components/EditPageButton';
import { FeatureFlag, IFeatureFlags } from '../Components/FeatureFlag';
import sustainableDevelopmentGoalsIcons from '../utils/sustainableDevelopmentGoalFactory';
import { track } from '../Analytics';
import { ToEcommerceObject } from '../Analytics/helpers';

export interface IMeetupPrice {
    Name: string;
    VatIncluded: boolean;
    Price: number;
}

export interface IAnchorMenuItem {
    Label: string;
    Hash: string;
}

export interface IModule {
    Name: string;
    DateRange: string;
    TimeRange: string;
    DurationInDays: number;
}

export interface IModules {
    Modules: IModule[];
    TotalDurationInDays: number;
}

export interface IMeetupPageProps {
    ActivityTypeEnumValue: number;
    Name: string;
    Title: string;
    Teaser: string;
    BodyText: string;
    CrmImageUrl: string;
    MeetupSignupUrl: string;
    MeetupLocationName: string;
    AddressLines: string;
    GeographicalLocation: string;
    ZipCode: string;
    City: string;
    Room: string;
    ExternalSignupUrl: string;
    OrganizerName: string;
    OrganizerUrl: string;
    EventNumber: string;
    AvailableSeats: number;
    Status: string;
    StatusCode: MeetupStatus;
    NumberOfSeats: number;
    NumberOfAttendees: number;
    RegistrationDeadline: Date;
    RegistrationDeadlinePassed: boolean;
    FormattedRegistrationDeadline: string;
    FormattedRegistrationDeadlineDate: string;
    FormattedRegistrationDeadlineTime: string;
    CoOrganizers?: string[];
    FormattedStartDateTime: string;
    FormattedEndDateTime: string;
    FormattedTimeStamp: string;
    FormattedStartDate: string;
    IsSameDate: boolean;
    TopPrice: string;
    OptionPrices: any;
    SustainableDevelopmentGoals: number[];
    SustainableDevelopmentTitle: string;
    SustainableDevelopmentText: string;
    SustainableDevelopmentGoalsUmbracoNodeId: number;
    Language: string;
    Prices: any;
    FromPrice: number;
    Type: string;
    TypeName: string;
    AnchorMenu: IAnchorMenuItem[];
    IntroImage?: string;
    ModulesWrapper?: IModules;
    classes: any;
    DocumentsEndpoint: string;
    Id: number;
    ShowEditLink: boolean;
    FeatureFlags: IFeatureFlags;
    CommuteAppTipTitle: string;
    CommuteAppTipDescription: string;
    CommuteAppTipLink: string;
}

const styles: StyleSheetThemed = (theme) => ({
    inlinePageContainers: {
        paddingTop: '40px',
        paddingBottom: '40px',

        [theme.breakpoints.up('sm')]: {
            paddingTop: '100px',
            paddingBottom: '100px',
        },
    },
    topPriceOffset: {
        [theme.breakpoints.up('sm')]: {
            marginTop: '39px',
        },
    },
    priceWrapperStylingObject: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '14px',
        lineHeight: '22px',

        '& $p': {
            margin: 0,
            fontSize: '16px',
            lineHeight: '30px',
        },

        [theme.breakpoints.up('sm')]: {
            fontSize: '16px',
            lineHeight: '30px',
        },
    },
    infoWrapperStylingObject: {
        '& $p': {
            margin: 0,
            fontSize: '16px',
            lineHeight: '30px',
        },

        [theme.breakpoints.up('sm')]: {
            maxWidth: '300px',
        },
    },
    textTag: {
        fontWeight: 600,
        color: theme.colors.brandPrimary,
        fontSize: '14px',
        lineHeight: '32px',
        margin: 0,

        [theme.breakpoints.up('sm')]: {
            fontSize: '16px',
            lineHeight: '19px',
            margin: '0 0 9px 0',
        },
    },
    textTitle: {
        marginTop: 0,
        fontSize: '30px',
        lineHeight: '45px',
        marginBottom: '9px',

        [theme.breakpoints.up('sm')]: {
            marginBottom: '36px',
        },
    },
    signupButtonWrapper: {
        width: '100%',
        '& $p': {
            marginBottom: '16px',
        },

        marginTop: '20px',

        [theme.breakpoints.up('sm')]: {
            marginTop: '30px',
        },
    },
    meetupPage: {
        color: 'inherit',
    },
    noSidePadOnDesktop: {
        '$meetupPage &': {
            [theme.breakpoints.up('sm')]: {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
    },
    sdgViewbox: {
        marginTop: '0',
    },
    sdgViewboxWithoutImage: {
        marginTop: '0',
        width: '100%',

        [theme.breakpoints.up('lg')]: {
            marginTop: '80px',
            width: 'auto',
        },
    },
    commuteAppWrapperStylingObject: {
        textAlign: 'center',
        fontSize: '14px',

        [theme.breakpoints.up('md')]: {
            textAlign: 'left',
        },
    },
});

export interface IWrapperProps {
    classes?: any;
    children?: React.ReactNode;
}

const InfoWrapper = ({ classes, children }: IWrapperProps) => {
    return <div className={classes.infoWrapperStylingObject}>{children}</div>;
};

const PriceWrapper = ({ classes, children }: IWrapperProps) => {
    return <div className={classes.priceWrapperStylingObject}>{children}</div>;
};

const CommuteAppWrapper = ({ classes, children }: IWrapperProps) => {
    return (
        <div className={classes.commuteAppWrapperStylingObject}>{children}</div>
    );
};

const StyledPriceWrapper = injectSheet(styles)(PriceWrapper);
const StyledInfoWrapper = injectSheet(styles)(InfoWrapper);
const StyledCommuteAppWrapper = injectSheet(styles)(CommuteAppWrapper);

class MeetupPage extends React.Component<IMeetupPageProps> {
    componentDidMount() {
        if (this.props) {
            track().ecommerce.viewItem(
                ToEcommerceObject(this.props, 'arrangement')
            );
        }
    }
    public render() {
        // Start of temporary styling components
        const smallerFontSize = { fontSize: '14px' };
        // End of temporary styling components
        let topImage;
        if (
            this.props.CrmImageUrl !== null &&
            this.props.CrmImageUrl.trim() !== ''
        ) {
            topImage = (
                <Grid item xs={12}>
                    <p>
                        <img
                            style={{ maxWidth: '100%', marginBottom: '30px' }}
                            src={this.props.CrmImageUrl}
                        />
                    </p>
                </Grid>
            );
        }

        const meetupUrl = this.props.MeetupSignupUrl;

        const coOrganizers = this.props.CoOrganizers
            ? this.props.CoOrganizers.map((coOrganizer, index) => {
                return (
                    <div key={index}>
                        <p>{coOrganizer}</p>
                    </div>
                );
            })
            : null;

        const coOrganizersElement = () => {
            if (!coOrganizers || coOrganizers.length === 0) {
                return null;
            }
            return (
                <div>
                    <p style={{ fontWeight: 600 }}>Medarrangører</p>
                    {coOrganizers}
                </div>
            );
        };

        const optionPrices =
            this.props.OptionPrices != null &&
                this.props.OptionPrices.length > 0
                ? this.props.OptionPrices.map((price, index) => {
                    return (
                        <StyledPriceWrapper key={index}>
                            <p style={{ maxWidth: '200px' }}>{price.Name}</p>
                            <p style={{ whiteSpace: 'nowrap' }}>
                                {price.Price}
                            </p>
                        </StyledPriceWrapper>
                    );
                })
                : null;

        const prices =
            this.props.Prices != null && this.props.Prices.length > 0 ? (
                this.props.Prices.map((price, index) => {
                    return (
                        <StyledPriceWrapper key={index}>
                            <p style={{ maxWidth: '200px' }}>{price.Name}</p>
                            <p style={{ whiteSpace: 'nowrap' }}>
                                {price.Price}
                            </p>
                        </StyledPriceWrapper>
                    );
                })
            ) : (
                <p>Ikke oplyst</p>
            );
        const deadline = () => {
            // Hvis arrangement har flere end 10 ledige pladser eller er udsolgt vises tilmeldingsfrist. Ellers vises antal ledige pladser.
            if (
                this.props.AvailableSeats > 10 ||
                this.props.AvailableSeats === 0
            ) {
                return (
                    <p style={smallerFontSize}>
                        Tilmeldingsfrist{' '}
                        {this.props.FormattedRegistrationDeadline}
                    </p>
                );
            } else if (this.props.AvailableSeats === 1) {
                return (
                    <p style={smallerFontSize}>
                        {this.props.AvailableSeats} plads tilbage
                    </p>
                );
            } else {
                return (
                    <p style={smallerFontSize}>
                        {this.props.AvailableSeats} pladser tilbage
                    </p>
                );
            }
        };

        const dates = () => {
            if (this.props.IsSameDate) {
                return (
                    <div>
                        <p>{this.props.FormattedStartDate}</p>
                        <p>{this.props.FormattedTimeStamp}</p>
                    </div>
                );
            } else {
                return (
                    <div>
                        <p>Fra {this.props.FormattedStartDateTime}</p>
                        <p>Til {this.props.FormattedEndDateTime}</p>
                    </div>
                );
            }
        };

        const location = () => {
            let locationText = `${this.props.MeetupLocationName}, ${this.props.City}`;
            if (this.props.Type === 'Webinar') {
                locationText = 'Online Arrangement';
            }
            if (!this.props.City) {
                locationText = this.props.MeetupLocationName;
            }
            if (!this.props.MeetupLocationName) {
                locationText = this.props.City;
            }

            return <p style={{ fontWeight: 600 }}>{locationText}</p>;
        };

        const anchorMenu = this.props.AnchorMenu
            ? this.props.AnchorMenu.map((item, index) => {
                return {
                    href: item.Hash,
                    title: item.Label,
                };
            })
            : [];

        const topImageClassName = classNames(this.props.classes.topImage, {
            [this.props.classes.topImageHasAnchorMenu]: this.props.AnchorMenu,
        });

        return (
            <React.Fragment>
                <FeatureFlag show={this.props.FeatureFlags.ShowAnchorMenu}>
                    <AnchorMenu items={anchorMenu} />
                </FeatureFlag>

                <Page
                    condensed
                    hasAnchorMenu={this.hasAnchorMenu()}
                    hasTopImage={Boolean(this.props.IntroImage)}
                >
                    {this.props.IntroImage && (
                        <TopCoverImage src={this.props.IntroImage} />
                    )}
                    <PageSection alternate>
                        <Grid container>
                            {/* Workaround until we have an offset mechanism - I'll bake it into the design system in a future version soon */}
                            <Grid
                                item
                                sm={1}
                                className={
                                    this.props.classes.noSidePadOnDesktop
                                }
                            />
                            <Grid
                                item
                                xs={12}
                                sm={5}
                                className={
                                    this.props.classes.noSidePadOnDesktop
                                }
                            >
                                <p className={this.props.classes.textTag}>
                                    {this.props.Type}
                                </p>
                                <Heading
                                    is="h1"
                                    size={600}
                                    className={this.props.classes.textTitle}
                                >
                                    {this.props.Title}{' '}
                                    <EditPageButton
                                        showEditLink={this.props.ShowEditLink}
                                        nodeId={this.props.Id}
                                    />
                                </Heading>
                                <Paragraph half noMargin>
                                    {this.props.Teaser}
                                </Paragraph>
                            </Grid>
                            {/* Workaround until we have an offset mechanism - I'll bake it into the design system in a future version soon */}
                            <Grid
                                item
                                sm={3}
                                className={
                                    this.props.classes.noSidePadOnDesktop
                                }
                            />
                            <Grid
                                item
                                xs={12}
                                sm={3}
                                className={
                                    this.props.classes.noSidePadOnDesktop
                                }
                            >
                                <div
                                    className={
                                        this.props.classes.topPriceOffset
                                    }
                                >
                                    <StyledInfoWrapper>
                                        {location()}
                                        {dates()}
                                        {this.props.TopPrice !== null && (
                                            <p style={{ fontWeight: 600 }}>
                                                {this.props.TopPrice}
                                            </p>
                                        )}
                                    </StyledInfoWrapper>
                                </div>
                            </Grid>
                            {/* Workaround until we have an offset mechanism - I'll bake it into the design system in a future version soon */}
                            <Grid
                                item
                                sm={1}
                                className={
                                    this.props.classes.noSidePadOnDesktop
                                }
                            />
                            <Grid
                                container
                                direction="column"
                                xs={12}
                                alignItems="center"
                            >
                                <div
                                    className={
                                        this.props.classes.signupButtonWrapper
                                    }
                                >
                                    <SignupButton
                                        meetupType={this.props.Type}
                                        meetupTitle={this.props.Title}
                                        helpText={deadline()}
                                        signupUrl={meetupUrl}
                                        statusCode={this.props.StatusCode}
                                        meetup={this.props}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </PageSection>

                    <PageSection>
                        <Grid container>
                            {this.props.SustainableDevelopmentGoals &&
                                this.props.SustainableDevelopmentGoals.length >
                                0 && (
                                    <Grid
                                        item
                                        xs={12}
                                        lg={1}
                                        xl={3}
                                        className={
                                            this.props.classes
                                                .noSidePadOnLargeDesktop
                                        }
                                    />
                                )}
                            <Grid item xs={12} lg={7} xl={6}>
                                {topImage}
                                <SectionHeader hash="beskrivelse">
                                    Beskrivelse
                                </SectionHeader>
                                <Richtext>{this.props.BodyText}</Richtext>
                            </Grid>

                            {((this.props.SustainableDevelopmentGoals &&
                                this.props.SustainableDevelopmentGoals.length >
                                0) ||
                                this.props.Type !== 'Webinar') && (
                                    <Grid
                                        item
                                        xs={12}
                                        lg={4}
                                        xl={3}
                                        className={
                                            topImage
                                                ? this.props.classes.sdgViewbox
                                                : this.props.classes
                                                    .sdgViewboxWithoutImage
                                        }
                                    >
                                        {this.props.SustainableDevelopmentGoals &&
                                            this.props.SustainableDevelopmentGoals
                                                .length > 0 && (
                                                <>
                                                    <EditPageButton
                                                        showEditLink={
                                                            this.props.ShowEditLink
                                                        }
                                                        nodeId={
                                                            this.props
                                                                .SustainableDevelopmentGoalsUmbracoNodeId
                                                        }
                                                    />

                                                    <MultiIconList
                                                        title={
                                                            this.props
                                                                .SustainableDevelopmentTitle
                                                        }
                                                        iconList={sustainableDevelopmentGoalsIcons(
                                                            this.props
                                                                .SustainableDevelopmentGoals,
                                                            this.props.Language
                                                        )}
                                                        richtext={
                                                            this.props
                                                                .SustainableDevelopmentText
                                                        }
                                                    />
                                                </>
                                            )}

                                        {this.props.Type !== 'Webinar' && (
                                            <StyledCommuteAppWrapper>
                                                <Heading
                                                    is="h5"
                                                    size={100}
                                                    noMargin
                                                >
                                                    {this.props.CommuteAppTipTitle}
                                                </Heading>
                                                <Richtext>
                                                    {
                                                        this.props
                                                            .CommuteAppTipDescription
                                                    }
                                                </Richtext>
                                            </StyledCommuteAppWrapper>
                                        )}
                                    </Grid>
                                )}
                        </Grid>
                    </PageSection>

                    <PageSection alternate>
                        <Grid container sm={12} justify="center">
                            <Grid item xs={12}>
                                <SectionHeader hash="praktisk-info">
                                    Praktisk Info
                                </SectionHeader>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <StyledInfoWrapper>
                                    <div>
                                        <p style={{ fontWeight: 600 }}>Hvor</p>
                                        <p>{this.props.MeetupLocationName}</p>
                                        <p>{this.props.AddressLines}</p>
                                        <p>
                                            {this.props.ZipCode}{' '}
                                            {this.props.City}
                                        </p>
                                        {this.props.Room !== null && (
                                            <p style={{ lineHeight: '30px' }}>
                                                {this.props.Room}
                                            </p>
                                        )}
                                        <br />
                                    </div>
                                    <div>
                                        <p style={{ fontWeight: 600 }}>
                                            Hvornår
                                        </p>
                                        {dates()}
                                        <br />
                                    </div>
                                    <div>
                                        <p style={{ fontWeight: 600 }}>Pris</p>
                                        {prices}
                                    </div>
                                </StyledInfoWrapper>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <StyledInfoWrapper>
                                    <div>
                                        <p style={{ fontWeight: 600 }}>
                                            Tilmeldingsfrist
                                        </p>
                                        <p>
                                            {
                                                this.props
                                                    .FormattedRegistrationDeadlineDate
                                            }
                                        </p>
                                        <p>
                                            {
                                                this.props
                                                    .FormattedRegistrationDeadlineTime
                                            }
                                        </p>
                                        <br />
                                    </div>
                                    {this.props.StatusCode == MeetupStatus.LastEntryDatePassed && (
                                        <div>
                                            <p style={{ fontWeight: 600 }}>
                                                Antal Tilmeldte
                                            </p>
                                            <p>{this.props.NumberOfAttendees}</p>
                                            <br />
                                        </div>)}
                                    {this.props.StatusCode !== MeetupStatus.LastEntryDatePassed && (
                                        <div>
                                            <p style={{ fontWeight: 600 }}>
                                                Antal pladser
                                            </p>
                                            <p>{this.props.NumberOfSeats}</p>
                                            <br />
                                        </div>
                                    )}
                                    <div>
                                        <p style={{ fontWeight: 600 }}>
                                            Ledige pladser
                                        </p>
                                        {this.props.StatusCode === MeetupStatus.LastEntryDatePassed && (<p>0</p>)}
                                        {this.props.StatusCode !== MeetupStatus.LastEntryDatePassed && (<p>{this.props.AvailableSeats}</p>)}
                                        
                                    </div>
                                    <div>
                                        {optionPrices !== null && (
                                            <p
                                                style={{
                                                    fontWeight: 600,
                                                    margin: 0,
                                                }}
                                            >
                                                Tilvalg
                                            </p>
                                        )}
                                        {optionPrices}
                                    </div>
                                </StyledInfoWrapper>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <StyledInfoWrapper>
                                    <div>
                                        <p style={{ fontWeight: 600 }}>
                                            Arrangementsnr.
                                        </p>
                                        <p>{this.props.EventNumber}</p>
                                        <br />
                                    </div>
                                    <div>
                                        <p style={{ fontWeight: 600 }}>
                                            Arrangør
                                        </p>
                                        <this.WithLink
                                            href={this.props.OrganizerUrl}
                                        >
                                            <p>{this.props.OrganizerName}</p>
                                        </this.WithLink>
                                        <br />
                                        {coOrganizersElement()}
                                        <br />
                                    </div>
                                    <div>
                                        <DocumentLoader
                                            DocumentsEndpoint={
                                                this.props.DocumentsEndpoint
                                            }
                                        />
                                    </div>
                                </StyledInfoWrapper>
                            </Grid>
                        </Grid>
                    </PageSection>
                </Page>
            </React.Fragment>
        );
    }

    private hasAnchorMenu = () => {
        return (
            this.props.AnchorMenu &&
            this.props.AnchorMenu.length &&
            this.props.FeatureFlags.ShowAnchorMenu
        );
    };

    private WithLink = (props) =>
        props.href ? (
            <Link className={props.className} url={props.href}>
                {props.children}
            </Link>
        ) : (
            props.children
        );
}

const StyledMeetupPage = injectSheet(styles)(MeetupPage);
export default StyledMeetupPage;
