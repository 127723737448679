import {
    ContactBand,
    Grid,
    Icons,
    Link,
    Page,
    PageHero,
    PageSection,
    Richtext,
    SectionHeader,
    MultiBox,
} from '@ingeniorforeningen/design-system';
import * as React from 'react';
import { IMeetupPageProps } from './MeetupPage';
import { FeatureFlag, IFeatureFlags } from '../Components/FeatureFlag';
import RelatedContent, { IRelatedContent } from '../Components/RelatedContent';
import { useState } from 'react';
import { track } from '../Analytics';
import { ToEcommerceList, ToEcommerceObject } from '../Analytics/helpers';
import { TMultiBoxItemProps } from '@ingeniorforeningen/design-system/build/components/Data Display/MultiBox/MultiBoxItem';

export interface ICourseCategoryPageProps {
    Header: string;
    Teaser: string;
    ParentLink: any;
    DescriptionHeadline: string;
    Description: string;
    Contact: IContact;
    Meetups: any;
    Meetup: IMeetupPageProps;
    classes?: any;
    Id: number;
    ShowEditLink: boolean;
    FeatureFlags: IFeatureFlags;
    RelatedContent: IRelatedContent;
}

export interface IContact {
    Image: string;
    Name: string;
    Occupation: string;
    Phone: string;
    Email: string;
    IsValid: boolean;
}

const CourseCategoryPage: React.FC<ICourseCategoryPageProps> = (props) => {
    const [hideForFullWidthContent, setHideForFullWidthContent] =
        useState(null);

    const formatPrice = (price) => {
        if (price === null || price === '') {
            return 'Nye datoer kommer snarest';
        }
        if (price === '0') {
            return 'Gratis';
        }

        return `Pris fra ${price} kr. ekskl. moms`;
    };

    const onFullWidthContentVisibilityChange = (isVisible) => {
        if (props.Meetup) {
            setHideForFullWidthContent(isVisible);
        }
    };

    const formatItems = (items): TMultiBoxItemProps[] => {
        return items
            ? items.map((item, index) => {
                  var formattedItem = {} as TMultiBoxItemProps;
                  Object.keys(item).forEach((key) => {
                      const meetup = item.Meetup as IMeetupPageProps;
                      if (meetup) {
                          const ecommerceMeetup = ToEcommerceObject(
                              meetup,
                              'kursus',
                              'kursusoversigt',
                              index
                          );
                          ecommerceMeetup.affiliation = 'lokation ikke valgt';
                          formattedItem.onItemClicked = () =>
                              track().ecommerce.selectItem(ecommerceMeetup);
                      }
                      if (key === 'Price') {
                          formattedItem['tagline'] = formatPrice(item.Price);
                      } else {
                          formattedItem[key.toLowerCase()] = item[key];
                      }
                      // The "meetup" is a infoPage or an articlePage and therefor shouldn't fallback to "dates comming soon"-text
                      if (
                          (key === 'PageType' && item[key] === 'articlePage') ||
                          item[key] === 'infoPage' ||
                          item[key] === 'courseCategoryPage'
                      ) {
                          formattedItem['tagline'] = '';
                      }
                  });
                  return formattedItem;
              })
            : [];
    };

    React.useEffect(() => {
        if (props.Meetups) {
            const ecommerceMeetups = props.Meetups.map(
                (meetup: ICourseCategoryPageProps) => meetup.Meetup
            );

            const filteredMeetups = ToEcommerceList(
                'kursus',
                ecommerceMeetups,
                'kursusoversigt'
            );

            if (filteredMeetups && filteredMeetups.length > 0) {
                track().ecommerce.viewItemList(filteredMeetups);
            }
        }
    }, [props.Meetups]);

    return (
        <React.Fragment>
            <Page>
                <PageHero
                    header={props.Header}
                    editPage={{ show: props.ShowEditLink, nodeId: props.Id }}
                    teaser={props.Teaser}
                />

                <FeatureFlag show={props.FeatureFlags.ShowCoursesBackButton}>
                    <Grid
                        container
                        noGutter
                        alignItems="flex-start"
                        direction="row"
                        justify="center"
                    >
                        <Grid item xs={12} slim>
                            <Link url={props.ParentLink.Link}>
                                <Icons.ArrowDown
                                    style={{
                                        transform: 'rotate(90deg)',
                                        marginBottom: '-9px',
                                    }}
                                />
                                {props.ParentLink.Title}
                            </Link>
                        </Grid>
                    </Grid>
                </FeatureFlag>
                <Grid container spacing={40}>
                    {props.Meetups && (
                        <Grid
                            container
                            spacing={0}
                            alignItems="flex-start"
                            direction="row"
                            justify="center"
                        >
                            <Grid item xs={12}>
                                <MultiBox items={formatItems(props.Meetups)} />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid container spacing={40}>
                    <Grid item sm={6} style={{ textAlign: 'left' }}>
                        <br />
                        <SectionHeader hash="description">
                            {props.DescriptionHeadline}
                        </SectionHeader>
                        <Richtext>{props.Description}</Richtext>
                    </Grid>
                </Grid>
                <RelatedContent
                    relatedContent={props.RelatedContent}
                    showEditLink={props.ShowEditLink}
                    onFullWidthContentVisibilityChange={
                        onFullWidthContentVisibilityChange
                    }
                />
            </Page>
            {Boolean(props.Contact.IsValid) && (
                <PageSection alternate>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <SectionHeader hash="vil-du-vide-mere" invisible>
                                Vil du vide mere
                            </SectionHeader>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                        <ContactBand {...props.Contact} />
                    </Grid>
                </PageSection>
            )}
        </React.Fragment>
    );
};

export default CourseCategoryPage;
