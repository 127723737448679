import { AppShell } from '@ingeniorforeningen/design-system';
import {
    AppShell as FigurineAppShell,
    THeaderProps,
    TFooterProps,
} from '@ingeniorforeningen/figurine-core';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import ReactDOMClient from 'react-dom/client';
import * as ReactDOMServer from 'react-dom/server';

import ArticlePage from './Pages/ArticlePage';
import ConferencePage from './Pages/ConferencePage';
import CourseCategoryPage from './Pages/CourseCategoryPage';
import CourseOverviewPage from './Pages/CourseOverviewPage';
import CoursePage from './Pages/CoursePage';
import DictionaryPage from './Pages/DictionaryPage';
import EmployeesPage from './Pages/EmployeesPage';
import Frontpage from './Pages/Frontpage';
import GenericOverviewPage from './Pages/GenericOverviewPage';
import InfoPage from './Pages/InfoPage';
import VideoPage from './Pages/VideoPage';
import PodcastPage from './Pages/PodcastPage';
import MeetupFrontpage from './Pages/MeetupFrontpage';
import MeetupPage from './Pages/MeetupPage';
import MemberSignupPage from './Pages/MemberSignupPage';
import NetworkOverviewPage from './Pages/NetworkOverviewPage';
import NetworkPage from './Pages/NetworkPage';
import NewsOverviewPage from './Pages/NewsOverviewPage';
import PageNotFound from './Pages/PageNotFound';
import CludoSearchPage from './Pages/CludoSearchPage';
import SectionFrontpage from './Pages/SectionFrontpage';
import ThemeOverviewPage from './Pages/ThemeOverviewPage';
import ThemePage from './Pages/ThemePage';
import TopicPage from './Pages/TopicPage';
import { FeatureFlag, IFeatureFlags } from './Components/FeatureFlag';
import uncapitalizeObject from './utils/uncapitalizeObject';
import { track, initTracker } from './Analytics';
import { resolveDeliveryLink } from './utils/resolveUmbracoLink';
import BreadcrumbWrapper from './Components/Breadcrumb';

export interface IAppProps {
    TemplateId: string;
    [propName: string]: any; // index signature to allow any other props.
    MitIdaBaseUrl: string;
    ContactHeader: string;
    PhoneLabel: string;
    PhoneUrl: string;
    MailLabel: string;
    MailUrl: string;
    CvrLabel: string;
    FacebookUrl: string;
    LinkedInUrl: string;
    TwitterUrl: string;
    InstagramUrl: string;
    PrivacyLinkLabel: string;
    PrivacyLinkUrl: string;
    TopMenu: any;
    LeftLinkList: any;
    RightLinkList: any;
    FeatureFlags: IFeatureFlags;
    Campaign?: any;
    Footer: any;
    UmbracoDataModel: any;
}

interface ITemplateMap {
    [propName: string]: any;
}

function templatePicker(templateId: string) {
    const templateMap: ITemplateMap = {
        frontpage: Frontpage,
        articlePage: ArticlePage,
        infoPage: InfoPage,
        videoPage: VideoPage,
        podcastPage: PodcastPage,
        genericOverviewPage: GenericOverviewPage,
        meetupFrontpage: MeetupFrontpage,
        meetupPage: MeetupPage,
        networkOverviewPage: NetworkOverviewPage,
        networkPage: NetworkPage,
        cludoSearchPage: CludoSearchPage,
        sectionFrontpage: SectionFrontpage,
        themeOverviewPage: ThemeOverviewPage,
        themePage: ThemePage,
        topicPage: TopicPage,
        pageNotFound: PageNotFound,
        newsOverviewPage: NewsOverviewPage,
        dictionaryPage: DictionaryPage,
        conferencePage: ConferencePage,
        coursePage: CoursePage,
        courseCategoryPage: CourseCategoryPage,
        courseOverviewPage: CourseOverviewPage,
        employeesPage: EmployeesPage,
        memberSignupPage: MemberSignupPage,
    };
    const template = templateMap[templateId];

    // If the template is not found or is not a function
    if (!template) {
        throw new Error('Not a valid template (' + templateId + ')');
    }

    return template;
}

class App extends React.Component<
    IAppProps,
    { headerData: THeaderProps; footerData: TFooterProps }
> {
    // Remove the server-side injected CSS.
    constructor(props) {
        super(props);
        initTracker(props.useGtmProdContainer);
    }

    public componentWillMount = async () => {
        const data = JSON.parse(this.props.UmbracoDataModel);

        this.setState({
            headerData: {
                breakingBannerContent: data?.properties?.breakingLink &&
                    data?.properties?.breakingText && (
                        <a
                            href={
                                resolveDeliveryLink(
                                    data?.properties.breakingLink?.[0]
                                ).href
                            }
                        >
                            {data?.properties.breakingText}
                        </a>
                    ),
                actionLink:
                    data?.properties?.selfServiceLink &&
                    resolveDeliveryLink(data?.properties?.selfServiceLink?.[0]),
                showRegister: true,
                localLinks: data?.properties?.websiteMenu?.items.map((item) => {
                    return {
                        header: item?.content?.properties?.header,
                        href: resolveDeliveryLink(
                            item?.content?.properties?.link?.[0]
                        ).href,
                        items: item?.content?.properties?.linkList.map(
                            (link) => ({
                                header: link.title,
                                links: link?.children.map((child) =>
                                    resolveDeliveryLink(child)
                                ),
                            })
                        ),
                        readMore:
                            item?.content?.properties?.readMoreLink &&
                            resolveDeliveryLink(
                                item?.content?.properties?.readMoreLink?.[0]
                            ),
                    };
                }),
                search:
                    data?.properties?.searchLink &&
                    resolveDeliveryLink(data?.properties.searchLink?.[0]),
                selectedSite: 'IDA.DK',
                localLinksLoading: false,
                languages: [
                    {
                        code: 'da',
                        label: 'Dansk',
                        href: '/',
                    },
                    {
                        code: 'en',
                        label: 'English',
                        href: 'https://english.ida.dk/',
                    },
                ],
            },
        });

        this.setState({
            footerData: {
                links:
                    data?.properties?.linkList &&
                    data?.properties?.linkList?.items &&
                    data?.properties?.linkList?.items?.map((item) => ({
                        header: item.content?.properties?.title,
                        items: item.content?.properties?.linkList?.map((link) =>
                            resolveDeliveryLink(link)
                        ),
                    })),
                relatedSitesHeader: data?.properties?.externalLinksHeader,
                relatedSites:
                    data?.properties?.externalLinks &&
                    data?.properties.externalLinks.map((link) =>
                        resolveDeliveryLink(link)
                    ),
                socials: {
                    twitterUrl: data?.properties?.xLink,
                    linkedInUrl: data?.properties?.linkedInLink,
                    facebookUrl: data?.properties?.facebookLink,
                    instagramUrl: data?.properties?.instagramLink,
                },
                contact: {
                    header: data?.properties?.contactHeader,
                    description: data?.properties?.contactContent,
                    phone: data?.properties?.phoneLink
                        ? resolveDeliveryLink(data?.properties.phoneLink?.[0])
                        : { text: 'Tlf. 33 18 48 48', href: 'tel:33184848' },
                    mail: data?.properties?.mailLink
                        ? resolveDeliveryLink(data?.properties.mailLink?.[0])
                        : {
                              text: 'Skriv til os her',
                              href: 'https://ida.dk/kontakt',
                          },
                },
            },
        });
    };

    public componentDidMount() {
        track().general.pageView({
            nodeId: this.props.Id,
            pageTitle: this.props.Title,
            section: this.props.Section,
        });
        const jssStyles = document.getElementById('server-side-styles');
        if (jssStyles && jssStyles.parentNode) {
            jssStyles.parentNode.removeChild(jssStyles);
        }
    }

    public render() {
        const Page = templatePicker(this.props.TemplateId);
        const topMenu = uncapitalizeObject(this.props.TopMenu);

        return (
            <>
                <FigurineAppShell
                    headerData={this.state?.headerData}
                    footerData={this.state?.footerData}
                    language="da"
                >
                    <AppShell>
                        <FeatureFlag
                            show={this.props.FeatureFlags.ShowBreadcrumb}
                        >
                            <BreadcrumbWrapper
                                breadcrumbs={this.props.Breadcrumbs}
                            />
                        </FeatureFlag>
                        <div
                            style={{ minHeight: 'calc(100vh - 300px)' }}
                            id="content"
                        >
                            <Page {...this.props} />
                        </div>
                    </AppShell>
                </FigurineAppShell>
            </>
        );
    }
}
// tslint:disable-next-line:no-string-literal
global['React'] = React;
// tslint:disable-next-line:no-string-literal
global['ReactDOM'] = ReactDOM;
global['ReactDOMClient'] = ReactDOMClient;
// tslint:disable-next-line:no-string-literal
global['ReactDOMServer'] = ReactDOMServer;
// tslint:disable-next-line:no-string-literal
global['App'] = App;

export default App;
