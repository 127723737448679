import React from 'react';

export interface IFeatureFlags {
    ShowAnchorMenu: boolean;
    ShowCoursesBackButton: boolean;
    ShowBreadcrumb: boolean;
    ShowInfoBackButton: boolean;
}

export const FeatureFlag = ({ show, children }) => {
    return <>{show && <>{children}</>}</>;
};
